import { createMachine, assign } from 'xstate'
import * as jose from 'jose'
import apiInterviewVersions from '../services/api/interviewVersion'
import apiHMS from '../services/api/thirdParty'

const isReady = localStorage.getItem('isReady')

const getRoomData = async (managementToken, roomName) => {
  const response = await apiHMS.getRoomData(managementToken, roomName)
  localStorage.setItem('management_token', response.token)
  return response
}

const getRoomCode = async (managementToken, roomId) => {
  const response = await apiHMS.getRoomCode(managementToken, roomId)
  return response.data.find(
    (roomRoleData) => roomRoleData.role === 'interviewer',
  )
}

const interviewMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEkB2AXMAnAbgSzAHcACAWQEMBjACz1TADoAbAe3IjqgGIIX6G6OFgGtGaTLgIkKNOo1btOCQS0rl0ePgG0ADAF1dexKAAOLWHg19jIAB6IATADYAjAwCcADgAsAZne+Tt6e7i4A7ACsADQgAJ6I3g6eDN4Rvi4ROkm+OWEuAL75MeLY+ERkVLT8Chyo3NhYLFgMJkzqAGZNALYMJZLlMlXybLVQyqhCalaohoY2ZhbTNvYIzm5efgFBIeHRcYi+DhEMmTpZvp4RTr4RBUUgfWXSlXItWGCPUlxzSCALlppUMtEHlvB4dE4jjcAu4wiEYvEEP5fCkHO4nBEHMjPGFXBFCsUMKUpBVZPwTO9PkRvi4jL9-ktfitQeDIWkIjC4e4ESCXGCMddoWidHl3ASHkT+s8yYwKR9JU9vg46aZzADrEzeWD3BCoRz-FyeQgXBCUriImFUi5Amc+eKqdKhm95RJFVpfCq-mrGaBmXzWXrOfD9ggLW5wrD0V4Qr5vPaFSTBq85Q7vt5PQzAcCECydWzoQbg4jLslrUlLZ4nO4HDp3GL7g7SU6UwnqVoIhnvVnNTn-XnA4XuSGHN53B40i5ralfGELtb467Ey9yZTW4Rvk5O4tu76tQH2UGh4irR40RWwjqIp5rU4F8SBsvZe8AGp4CBgFip2DochYdAOn5VW3DVd2NDIdBOM4dBcBxLTCEVLiNQJkmnBCMjrSt3DjBs1ybZN3gAFTAWx-zXLhv1-UjFyIQCvWAoEe0nTJINtWDvHguE9kRJwQgYJwL1xFwq1uTwkjvKU8JXMAADFui-H8-wA-R5i7EC7EQJiINOaC2I4xCQ2vFioNg2ETXcfFxVQFh33gX5GyTegVPo7MAFonCNNyGCg7yfLOMJxKeSThkUOonPVBjQMSJCZw8c1zLROtrn8nDqMdfCXXvQgwp9dTQ1HLyAkE8I8icHRfCNVIIIjS1MRyLFbgCpcZWdV930-Ndsp3XL0RRBwXBxJwqxnHFayQ0I+IvK4QmgsIwgcZxGofZq5SIkiHU6tSVnRY50QGlxQivEsjTyBxWW8GDfAhYJY0WtKpNkrAunW+lVIi7rrgYPqBqGuF4KPDSdDBOaglCLI+R67DCVSoKGHwNrnqA8Ls2tZj9VxGtnAiPwxuSatzJ8dE4RCHFbphzA1o6l7nMYm4tMKyEsgxbGQzmqqrhnTJRPRiyocymHOkehG6KRmnUfpjGmfKkNJ3Wc0RR0PaLmS3mJIcxgGiaDa3pWLGx1rGdXGKoSyqQ9jPucKscXOs5BtvFK+bVhhYAAV0oSg4FsxGcq2j6vtxH6Rv+hBKoYCNBsxDZZrtwogA */
    id: 'Interview Machine',
    predictableActionArguments: true,
    initial: 'loading',
    events: {
      type: 'startInterview',
    },
    services: {
      fetchInterview: {
        data: {},
      },
      fetchInterviewAfterExpiration: {
        data: {},
      },
    },
    context: {
      finished: false,
      ended: false,
      started: false,
      rescheduleEnabled: false,
      interviewType: '',
      interviewTime: '',
      interviewId: '',
      ready: true,
      showCounter: true,
      errorMsg: '',
      candidateName: '',
    },
    states: {
      loading: {
        invoke: {
          src: 'fetchInterview',
          onDone: {
            actions: [
              'assignInterviewResponseToContext',
              'clearLocalStorageIfReady',
            ],
            target: 'preInterview',
          },
          onError: {
            actions: 'assignErrorToContext',
            target: 'error',
          },
        },
      },
      preInterview: {
        always: [
          { target: 'preInPersonInterview', cond: 'isPreInPersonInterview' },
          { target: 'preVideoInterview', cond: 'isPreVideoInterview' },
          { target: 'preTextInterview', cond: 'isPreTextInterview' },
          { target: 'preFormInterview', cond: 'isPreFormInterview' },
          { target: 'inPersonInterview', cond: 'isInPersonInterview' },
          { target: 'videoInterview', cond: 'isVideoInterview' },
          { target: 'textInterview', cond: 'isTextInterview' },
          { target: 'formInterview', cond: 'isFormInterview' },
          { target: 'success', cond: 'isSubmitted' },
          { target: 'end', cond: 'isEnded' },
          { target: 'localStorageClearing', cond: 'localStorageNotEmpty' },
        ],
      },
      preInPersonInterview: {
        on: {
          startInterview: {
            target: 'inPersonInterview',
          },
        },
      },
      preVideoInterview: {
        entry: ['invokeApiInterviewVersions'],
        on: {
          startInterview: {
            target: 'videoInterview',
          },
        },
      },
      preTextInterview: {
        on: {
          startInterview: {
            target: 'textInterview',
          },
        },
      },
      preFormInterview: {
        on: {
          startInterview: {
            target: 'formInterview',
          },
        },
      },
      inPersonInterview: {},
      videoInterview: {},
      textInterview: {},
      formInterview: {},
      error: {
        invoke: {
          src: 'fetchInterviewAfterExpiration',
          onDone: {
            actions: ['assignRescheduleResponseToContext'],
          },
        },
      },
      success: {},
      end: {},
      localStorageClearing: {
        always: {
          target: 'preInterview',
          action: 'assignToClearLocalStorage',
        },
      },
    },
  },
  {
    guards: {
      isPreInPersonInterview: (context) =>
        context.interviewType === 'INPERSON' && !context.started,
      isPreVideoInterview: (context) =>
        context.interviewType === 'VIDEO' &&
        !context.finished &&
        !context.ended &&
        isReady !== 'true',
      isPreTextInterview: (context) =>
        context.interviewType === 'TEXT' &&
        !context.finished &&
        !context.ended &&
        !context.started,
      isPreFormInterview: (context) =>
        context.interviewType === 'FORM' &&
        !context.finished &&
        !context.ended &&
        isReady !== 'true',
      isInPersonInterview: (context) =>
        context.interviewType === 'INPERSON' && context.started,
      isVideoInterview: (context) =>
        context.interviewType === 'VIDEO' &&
        !context.finished &&
        !context.ended &&
        context.started &&
        isReady === 'true',
      isTextInterview: (context) =>
        context.interviewType === 'TEXT' &&
        !context.finished &&
        !context.ended &&
        context.started,
      isFormInterview: (context) =>
        context.interviewType === 'FORM' &&
        !context.finished &&
        !context.ended &&
        isReady === 'true',
      isSubmitted: (context) => context.finished,

      isEnded: (context) => context.ended && context.started,

      localStorageNotEmpty: (context) =>
        !context.finished &&
        !context.ended &&
        !context.started &&
        isReady === 'true',
    },
    actions: {
      assignInterviewResponseToContext: assign((context, event) => {
        const interviewToken = window.location.pathname.split('/')[2]
        const interviewId = jose.decodeJwt(interviewToken).interview_version_id
        return {
          finished: event.data?.isFinished || false,
          ended: event.data?.isEnded || false,
          started: event.data?.isStarted || false,
          rescheduleEnabled: event.data?.rescheduleEnabled || false,
          interviewType: event.data?.type || '',
          interviewTime: event.data?.time || '',
          interviewId: interviewId || '',
          candidateName: event.data?.candidateName || '',
        }
      }),
      assignRescheduleResponseToContext: assign((context, event) => ({
        rescheduleEnabled: event.data?.rescheduleEnabled || false,
        interviewType: event.data?.type || '',
        interviewTime: event.data?.time || '',
      })),

      assignErrorToContext: assign((context, event) => ({
        errorMsg: event.data?.response?.data?.description,
      })),
      clearLocalStorageIfReady: assign((context) => {
        if (isReady === 'true' && !context.started && !context.finished) {
          localStorage.clear()
          window.location.reload()
        }
      }),
      invokeApiInterviewVersions: async (context) => {
        const candidateRoleRoomName = localStorage.getItem(
          'candidateRoleRoomName',
        )
        if (!candidateRoleRoomName) {
          try {
            const tokenResponse =
              await apiInterviewVersions.getManagementToken()
            const managementToken = tokenResponse.token
            const roomName = `interview_${context.interviewId}`

            const roomDataResponse = await getRoomData(
              managementToken,
              roomName,
            )
            localStorage.setItem('room_id', roomDataResponse.id)
            localStorage.setItem('candidateRoleRoomName', roomDataResponse.name)

            const candidateRoleRoomCode = localStorage.getItem(
              'candidateRoleRoomCode',
            )
            if (!candidateRoleRoomCode) {
              const roomCodeResponse = await getRoomCode(
                managementToken,
                roomDataResponse.id,
              )
              localStorage.setItem(
                'candidateRoleRoomCode',
                roomCodeResponse.code,
              )
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        }
      },
    },
  },
)
export default interviewMachine
