import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Card from 'react-bootstrap/Card'
import { FormCard, FormCardRibbon, Typography } from '../styled'
import theme from '../../constants/theme'

export default function QuestionCard({ positionTitle }) {
  const { t } = useTranslation()
  return (
    <FormCard>
      <FormCardRibbon />
      <Card.Body className="pt-4 pb-5 px-5">
        <Card.Title>
          <Typography color={theme.brandColorOrange} size="30px" bold>
            {`"${positionTitle}"`}
          </Typography>
          <Typography color={theme.headersMiniTitle} size="30px" bold>
            {' Application Form'}
          </Typography>
        </Card.Title>
        <Card.Text className="pt-4">
          <Typography color={theme.brandColorOrange} size="14px">
            {`* ${t('general.required')}`}
          </Typography>
        </Card.Text>
      </Card.Body>
    </FormCard>
  )
}

QuestionCard.propTypes = {
  positionTitle: PropTypes.string.isRequired,
}
