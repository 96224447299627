import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StaticDateTimePicker } from '@mui/x-date-pickers'
import PrimaryButton from '../buttons/PrimaryButton'
import apiCalendar from '../../services/api/calendar'
import theme from '../../constants/theme'
import {
  CalendarPickerContainer,
  CalendarTimeBox,
  Clock,
  OverlayDiv,
  Title,
} from '../styled'
import { months } from '../../constants/months'
import { ReactComponent as TimeCircle } from '../../assets/svgs/timeCircle.svg'
import { ReactComponent as CalendarSquare } from '../../assets/svgs/calendar.svg'

function Calendar({ endDate, title, duration }) {
  const { t, i18n } = useTranslation()
  const locale = i18n?.language
  const { token } = useParams()
  const navigate = useNavigate()

  const today = dayjs()
  const scheduleDuration = dayjs().add(duration, 'day')
  const maxDate = dayjs(endDate).set('hour', -1).startOf('hour')
  const [time, setTime] = useState(
    new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
  )
  const [localTime, setLocalTime] = useState(
    `${today?.$y}-${today?.$M}-${today?.$D} ${today?.$H}:${today?.$m}:00`,
  )
  const [UTC, setUTC] = useState(today?.$d)

  const [isSchedule, setIsSchedule] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const UpdateTime = () => {
    setTime(
      new Date().toLocaleString([], { hour: '2-digit', minute: '2-digit' }),
    )
  }
  setInterval(UpdateTime, 1000)

  function handleDateTime(e) {
    if (e) {
      setLocalTime(`${e.$y}-${e.$M + 1}-${e.$D} ${e.$H}:${e.$m}:00`)
      setUTC(e.$d)
      setIsDisabled(false)
    }
  }

  const handleDecline = () =>
    apiCalendar.declineInterview(token).then(() => navigate(0))

  const handleSubmit = () => {
    apiCalendar
      .patch(token, { localTime, utc: UTC })
      .then(({ interviewToken }) => navigate(`/interview/${interviewToken}`))
  }

  const handleStartNow = () => {
    handleDateTime(dayjs(today))

    handleSubmit(dayjs(today))
  }

  React.useEffect(() => {
    handleDateTime(dayjs(today))
    setIsDisabled(true)
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} style={{ height: '100%' }}>
      {isSchedule && (
        <>
          <OverlayDiv onClick={() => setIsSchedule(false)} />
          <CalendarPickerContainer>
            <Row>
              <StaticDateTimePicker
                className="p-2 text-center"
                defaultValue={today}
                minDateTime={today}
                maxDateTime={
                  duration && scheduleDuration.isBefore(maxDate)
                    ? scheduleDuration
                    : maxDate
                }
                disablePast
                onChange={(e) => handleDateTime(e)}
                slots={{
                  actionBar: 'd-none',
                }}
              />
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <PrimaryButton
                  width="100%"
                  backgroundcolor="transparent"
                  border={`1px solid ${theme.talentinoRed}`}
                  hovercolor={theme.talentinoRed}
                  color={theme.talentinoRed}
                  onClick={handleDecline}
                  disabled={false}
                >
                  {t('calendar.declineInvitation')}
                </PrimaryButton>
              </Col>
              <Col md={6} sm={12}>
                <PrimaryButton
                  width="100%"
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  {t('general.submit')}
                </PrimaryButton>
              </Col>
            </Row>
          </CalendarPickerContainer>
        </>
      )}
      <div style={{ backgroundColor: theme.white, height: '100%' }}>
        <Clock style={{ flexGrow: 1 }}>
          <Title>{title}</Title>

          <CalendarTimeBox>
            <h1>
              <CalendarSquare /> {today?.$d.getDate()}{' '}
              {months[today?.$d.getMonth()][locale]}
            </h1>
            <span style={{ fontSize: '16px', fontWeight: '600' }}> - </span>
            <h1>
              <TimeCircle /> {time}
            </h1>
          </CalendarTimeBox>
          <Row>
            <Col sm={12} className="p-1">
              <PrimaryButton width="100%" onClick={handleStartNow}>
                {t('calendar.startNow')}
              </PrimaryButton>
            </Col>
            <div />
            <Col md={6} sm={12} className="p-1">
              <PrimaryButton
                width="100%"
                backgroundcolor="transparent"
                border={`1px solid ${theme.talentinoRed}`}
                hovercolor={theme.talentinoRed}
                color={theme.talentinoRed}
                onClick={handleDecline}
              >
                {t('calendar.declineInvitation')}
              </PrimaryButton>
            </Col>

            <Col md={6} sm={12} className="p-1">
              <PrimaryButton width="100%" onClick={() => setIsSchedule(true)}>
                {t('calendar.scheduleButton')}
              </PrimaryButton>
            </Col>
          </Row>
        </Clock>
      </div>
    </LocalizationProvider>
  )
}

Calendar.propTypes = {
  endDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
}

export default Calendar
