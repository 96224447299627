import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import Spinner from 'react-bootstrap/Spinner'
import BaseButton from './BaseButton'
import theme from '../../constants/theme'

function FormSubmitWithLoading({ text, onSubmit, isLoading, isDisabled }) {
  const { handleSubmit } = useFormContext()
  return (
    <BaseButton
      backgroundcolor={theme.brandColorOrange}
      hoverbackgroundcolor={theme.brandColorLightOrange}
      onClick={handleSubmit(onSubmit)}
      disabled={isLoading || isDisabled}
    >
      {isLoading && (
        <Spinner
          animation="border"
          variant="light"
          size="sm"
          className="me-2"
        />
      )}
      {text}
    </BaseButton>
  )
}

FormSubmitWithLoading.propTypes = {
  text: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

FormSubmitWithLoading.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  isDisabled: false,
}

export default FormSubmitWithLoading
