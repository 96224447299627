import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  DropdownToggleBtn,
  NotificationItemText,
  StyledDropdownMenu,
} from './dropdown'
import { Typography } from './styled'
import theme from '../constants/theme'
import BaseButton from './buttons/BaseButton'

export default function LanguagesDropdown() {
  const { i18n } = useTranslation()
  const [menu, setMenu] = React.useState(false)

  const locales = {
    en: { title: 'English' },
    ar: { title: 'العربية' },
    gr: { title: 'Deutsch' },
  }

  useEffect(() => {
    const dir = i18n.dir(i18n.language)
    document.documentElement.dir = dir
  }, [i18n, i18n.language])

  return (
    <Dropdown
      show={menu}
      onToggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggleBtn width="70px" tag="button">
        <Typography color={theme.brandColorOrange}>
          {
            locales[
              Object.keys(locales).filter((locale) => locale === i18n.language)
            ]?.title
          }
        </Typography>
      </DropdownToggleBtn>
      <StyledDropdownMenu>
        {Object.keys(locales).map((locale) => (
          <NotificationItemText>
            <BaseButton
              className="w-100 text-start"
              backgroundcolor="transparent"
              hoverbackgroundcolor="transparent"
              color={theme.headersMiniTitle}
              key={locale}
              type="button"
              onClick={() => i18n.changeLanguage(locale)}
            >
              {locales[locale].title}
            </BaseButton>
          </NotificationItemText>
        ))}
      </StyledDropdownMenu>
    </Dropdown>
  )
}
