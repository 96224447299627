/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import FullLogo from '../assets/logos/full-logo.png'
import {
  Footer,
  FooterContent,
  Typography,
  GradientBackground,
  JobApplicationPositionCard,
} from '../components/styled'
import storageApi from '../services/firebaseStorage'
import AbstractForm from '../components/forms/AbstractForm'
import theme from '../constants/theme'
import apiApplications from '../services/api/applications'
import NotificationMessagePage from '../components/notificationMessagePage'
import { noticePeriodOptions, source } from '../constants/candidate'
import ProgressBar from '../components/progress-bar/progressBar'
import { getFormattedDate, currency } from '../constants/position'
import {
  KEY_SUPPORTED_FORMATS,
  MAX_FILE_SIZE,
} from '../utilities/stringUtilities'
import apiInternalApplications from '../services/api/internalApplications'
import LanguagesDropdown from '../components/languageDropdwn'
import EliminatingQuestion from '../components/EliminatingQuestions'
import apiPositionEliminatingQuestions from '../services/api/positionEliminatingQuestions'

function JobApplicationForm({
  onBackClick,
  company,
  position,
  isInternalHiring,
}) {
  const { t, i18n } = useTranslation()
  const locale = i18n?.language
  const { token } = useParams()
  const [renderType, setRenderType] = useState('form')
  const [message, setMessage] = useState('')
  const [eliminatingQuestions, setEliminatingQuestions] = React.useState([])
  const [eliminatingAnswers, setEliminatingAnswers] = React.useState([])

  useEffect(() => {
    apiPositionEliminatingQuestions
      .getAllByPosition(position?.id)
      .then((res) => {
        setEliminatingQuestions(res)
      })
  }, [position])

  const PHONE_REG_EXP =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const progressRef = useRef(null)
  const errorRef = useRef(null)

  const handleClick = () => {
    onBackClick()
  }

  const handleUploadProgress = (node, progress) => {
    const progressNode = node
    progressNode.setAttribute('aria-valuenow', progress)
    progressNode.setAttribute(
      'style',
      `width: ${progress}%; background-color: ${theme.brandColorOrange}`,
    )
    progressNode.innerText = `${progress}%`
  }

  const handleSuccessfulUpload = (data, setIsLoading) => {
    let applicationRes = null
    const allData =
      eliminatingAnswers?.length > 0
        ? {
            ...data,
            eliminatingQuestionsAnswer: eliminatingAnswers,
          }
        : data
    if (isInternalHiring)
      applicationRes = apiInternalApplications.applyForPositionInternally
    else applicationRes = apiApplications.applyForPosition

    applicationRes(token, allData)
      .then((res) => {
        if (res.message === 'found') {
          setRenderType('error')
          setMessage(t('pages.applicationSubmitMsg'))
        } else {
          setRenderType('success')
          setMessage(t('pages.applyMsg', { position: position.title }))
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false))
  }

  const handleUploadError = (error) => {
    errorRef.current.innerText = error
  }

  const handleSubmit = async (data, setIsLoading) => {
    if (!data.resumes.length) {
      // eslint-disable-next-line no-alert
      alert(t('validation.resumes'))
      setIsLoading(false)
      return
    }
    storageApi.uploadFile(
      data.resumes[0],
      (progress) => handleUploadProgress(progressRef.current, progress),
      (url) =>
        handleSuccessfulUpload(
          {
            ...data,
            resumes: [url],
          },
          setIsLoading,
        ),
      (err) => handleUploadError(err),
    )
  }

  const externalHiringInputs = [
    {
      name: 'firstName',
      label: t('form.firstName'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'lastName',
      label: t('form.lastName'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'email',
      label: t('form.email'),
      type: 'email',
      required: true,
      width: 6,
    },
    {
      name: 'phone',
      label: t('form.phone'),
      type: 'number',
      required: true,
      width: 6,
    },
    {
      name: 'address',
      label: t('form.address'),
      type: 'text',
      placeholder: t('form.addressPlaceholder'),
      required: true,
      width: 6,
    },
    {
      name: 'title',
      label: t('form.title'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'experienceYears',
      type: 'number',
      label: t('form.yearsOfExperience'),
      required: true,
      width: 6,
    },
    {
      name: 'source',
      label: t('form.foundJobOn'),
      renderType: 'select',
      required: true,
      options: source.map((option) => ({
        value: option.value,
        label: option[locale],
      })),
      width: 6,
    },
    {
      name: 'expectedSalary',
      type: 'number',
      label: t('form.expectedSalary'),
      required: true,
      width: 4,
    },
    {
      name: 'currency',
      renderType: 'select',
      label: t('form.currency'),
      options: currency,
      width: 2,
    },
    {
      name: 'noticePeriod',
      label: t('form.noticePeriod'),
      renderType: 'select',
      required: true,
      options: noticePeriodOptions.map((option) => ({
        value: option.value,
        label: option[locale],
      })),
      width: 6,
    },
    {
      name: 'resumes',
      label: t('form.resumes'),
      renderType: 'files',
      required: true,
      width: 12,
      accept: '.doc*, .dot*, .pdf',
    },
  ]

  const internalHiringInputs = [
    {
      name: 'firstName',
      label: t('form.firstName'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'lastName',
      label: t('form.lastName'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'employeeId',
      label: t('form.employeeId'),
      type: 'text',
      required: true,
      width: 12,
    },
    {
      name: 'email',
      label: t('form.email'),
      type: 'email',
      required: true,
      width: 6,
    },
    {
      name: 'phone',
      label: t('form.phone'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'officeAddress',
      label: t('form.officeAddress'),
      type: 'text',
      placeholder: t('form.addressPlaceholder'),
      required: true,
      width: 6,
    },
    {
      name: 'currentTitle',
      label: t('form.currentTitle'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'joined',
      label: t('form.joinedSince', { company: company?.companyName }),
      type: 'date',
      max: getFormattedDate(new Date()),
      required: true,
      width: 6,
    },
    {
      name: 'currentDepartment',
      label: t('form.currentDepartment'),
      type: 'text',
      required: true,
      width: 6,
    },
    {
      name: 'resumes',
      label: t('form.resumes'),
      renderType: 'files',
      required: true,
      width: 12,
      accept: '.doc*, .dot*, .pdf',
    },
  ]

  const actions = [
    {
      text: t('general.back'),
      onClick: handleClick,
    },
    {
      field: 'submit',
      text: t('general.submit'),
      onClick: handleSubmit,
    },
  ]

  const externalHiringSchema = Yup.object().shape({
    firstName: Yup.string().required(t('general.required')),
    lastName: Yup.string().required(t('general.required')),
    email: Yup.string().email().required(t('general.required')),
    phone: Yup.string()
      .matches(PHONE_REG_EXP, `${t('validation.phone')}`)
      .required(t('general.required')),
    title: Yup.string().required(t('general.required')),
    address: Yup.string().required(t('general.required')),
    source: Yup.string().required(t('general.required')),
    experienceYears: Yup.number(t('validation.number'))
      .typeError(t('validation.number'))
      .integer(t('validation.integer'))
      .min(0, t('validation.positive'))
      .required(t('general.required')),
    expectedSalary: Yup.number(t('validation.number'))
      .typeError(t('validation.number'))
      .integer(t('validation.integer'))
      .positive(t('validation.invalidSalary'))
      .required(t('general.required')),
    noticePeriod: Yup.string().required(t('general.required')),
    resumes: Yup.mixed()
      .required(t('general.required'))
      .test(
        'fileUpload',
        t('general.required'),
        (value) => Object.keys(value).length,
      )
      .test(
        'fileUpload',
        t('validation.fileNumber'),
        (value) => Object.keys(value).length === 1,
      )
      .test(
        'fileSize',
        t('validation.fileSize'),
        (files) =>
          Object.keys(files).filter((file) => files[file].size <= MAX_FILE_SIZE)
            .length,
      )
      .test(
        'fileFormat',
        t('validation.fileFormate'),
        (files) =>
          Object.keys(files).filter(
            (file) => files[file].type === KEY_SUPPORTED_FORMATS.pdf,
          ).length,
      ),
  })

  const internalHiringSchema = Yup.object().shape({
    firstName: Yup.string().required(t('general.required')),
    lastName: Yup.string().required(t('general.required')),
    employeeId: Yup.string().required(t('general.required')),
    email: Yup.string().email().required(t('general.required')),
    phone: Yup.string()
      .matches(PHONE_REG_EXP, t('validation.phone'))
      .required(t('general.required')),
    currentTitle: Yup.string().required(t('general.required')),
    officeAddress: Yup.string().required(t('general.required')),
    joined: Yup.string().required(t('general.required')),
    currentDepartment: Yup.string().required(t('general.required')),
    resumes: Yup.mixed()
      .required(t('general.required'))
      .test(
        'fileUpload',
        t('general.required'),
        (value) => Object.keys(value).length,
      )
      .test(
        'fileUpload',
        t('validation.fileNumber'),
        (value) => Object.keys(value).length === 1,
      )
      .test(
        'fileSize',
        t('validation.fileSize'),
        (files) =>
          Object.keys(files).filter((file) => files[file].size <= MAX_FILE_SIZE)
            .length,
      )
      .test(
        'fileFormat',
        t('validation.fileFormate'),
        (files) =>
          Object.keys(files).filter(
            (file) => files[file].type === KEY_SUPPORTED_FORMATS.pdf,
          ).length,
      ),
  })

  const defaultValues = useMemo(
    () =>
      isInternalHiring
        ? {}
        : {
            noticePeriod: noticePeriodOptions[0].value,
            source: source[0].value,
          },
    [isInternalHiring, noticePeriodOptions, source],
  )

  function renderInfo() {
    return isInternalHiring ? (
      <>
        <Typography size="22px" bold>
          {position?.title}
        </Typography>
        <Typography size="18px">at {company?.companyName}</Typography>
        <Typography size="15px" className="my-3" regular>
          {t('pages.hello')}!
        </Typography>
        <Typography size="15px" regular>
          {t('pages.helloMsg', { company: company?.companyName })}
        </Typography>
        <Typography size="15px" className="my-3" regular>
          {t('pages.thanksMsg')}
        </Typography>
        <Typography size="18px">
          {t('pages.signature', { company: company?.companyName })}
        </Typography>
        <Typography size="22px" className="mt-4">
          {t('pages.applyYourApplication')}:
        </Typography>
      </>
    ) : (
      <Typography size="22px">{t('pages.applicationForm')}</Typography>
    )
  }

  if (renderType === 'error')
    return <NotificationMessagePage messageType="error" message={message} />

  if (renderType === 'success')
    return <NotificationMessagePage messageType="success" message={message} />

  return (
    <GradientBackground
      className="d-flex flex-column min-vh-100"
      background={theme.gradientBackground}
    >
      <Container className="mt-2 mb-3">
        <Row className="my-2 align-items-end">
          <Col sm="auto">
            <img height="auto" width="130px" src={FullLogo} alt="" />
          </Col>
          <Col />
          <Col sm="1">
            <LanguagesDropdown />
          </Col>
        </Row>
        <Row className="mt-4 pb-5 justify-content-center">
          <Col sm={12} md={8}>
            <JobApplicationPositionCard className="shadow bg-body rounded h-100 px-3 pt-2">
              <Row className="p-3 pb-0">{renderInfo()}</Row>
              <Row className="m-3">
                <AbstractForm
                  inputs={
                    isInternalHiring
                      ? internalHiringInputs
                      : externalHiringInputs
                  }
                  schema={
                    isInternalHiring
                      ? internalHiringSchema
                      : externalHiringSchema
                  }
                  actions={actions}
                  defaultValues={defaultValues}
                >
                  {eliminatingQuestions.length > 0 && (
                    <Typography
                      backgroundColor={theme.cardBorder}
                      color={theme.brandColorOrange}
                      size={20}
                      Arete-Global
                      className="d-block my-4"
                    >
                      Required Questions
                    </Typography>
                  )}
                  {eliminatingQuestions?.map((question, index) => (
                    <EliminatingQuestion
                      key={question.id}
                      question={question}
                      index={index + 1}
                      setEliminatingAnswers={setEliminatingAnswers}
                    />
                  ))}
                  <ProgressBar
                    ref={{
                      progressRef,
                      errorRef,
                    }}
                  />
                </AbstractForm>
              </Row>
            </JobApplicationPositionCard>
          </Col>
        </Row>
      </Container>
      <Footer className="fixed-bottom">
        {t('footer.TalentinoAI')}{' '}
        <FooterContent>© {new Date().getFullYear()}</FooterContent>{' '}
        {t('footer.allRightsReserved')}
      </Footer>
    </GradientBackground>
  )
}
JobApplicationForm.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  company: PropTypes.shape({
    companyName: PropTypes.string,
  }).isRequired,
  position: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  isInternalHiring: PropTypes.bool.isRequired,
}

export default JobApplicationForm
