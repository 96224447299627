/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'

import { ReactComponent as Mic } from '../../assets/svgs/mic.svg'
import { ReactComponent as Stop } from '../../assets/svgs/stop-circle.svg'
import { InterviewButtonsContainer } from '../styled'
import PrimaryButton from '../buttons/PrimaryButton'

export default function SpeechToText({
  setTranscribedText,
  transcribedText,
  onResetTranscript,
  isRecorderDisabled,
  isLoading,
  method,
  questionStatus,
  setQuestionStatus,
}) {
  const { t } = useTranslation()
  const {
    transcript,
    finalTranscript,
    interimTranscript,
    listening,
    resetTranscript,
  } = useSpeechRecognition()

  const startRecordController = () => {
    SpeechRecognition.startListening({ continuous: true })
  }

  const handleStartRecording = () => {
    setQuestionStatus('recording')
    startRecordController()
  }

  const handleStopRecording = () => {
    SpeechRecognition.stopListening()
    setQuestionStatus('stopped')
  }

  useEffect(() => {
    const currentTranscript =
      transcribedText !== ''
        ? `${finalTranscript}${interimTranscript}`
        : `${finalTranscript}${interimTranscript}`
    setTranscribedText(currentTranscript)
    method.setValue('answer', currentTranscript)
  }, [transcript, setTranscribedText])

  useEffect(() => {
    onResetTranscript(resetTranscript)
  }, [resetTranscript, onResetTranscript])

  return (
    <InterviewButtonsContainer>
      {((questionStatus === 'notAnswered' && !listening) ||
        (questionStatus === 'stopped' && !listening)) && (
        <PrimaryButton
          onClick={handleStartRecording}
          disabled={isRecorderDisabled || isLoading}
        >
          <Mic style={{ marginRight: '5px' }} />
          {t('interview.startListening')}
        </PrimaryButton>
      )}
      {questionStatus === 'recording' && listening && (
        <PrimaryButton
          backgroundcolor="#A93C38"
          hovercolor="#8B302E"
          onClick={handleStopRecording}
          disabled={isRecorderDisabled || isLoading}
        >
          <Stop style={{ marginRight: '5px' }} />
          {t('interview.stopListening')}
        </PrimaryButton>
      )}
    </InterviewButtonsContainer>
  )
}

SpeechToText.propTypes = {
  setTranscribedText: PropTypes.func.isRequired,
  transcribedText: PropTypes.string.isRequired,
  onResetTranscript: PropTypes.func.isRequired,
  isRecorderDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  method: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    resolver: PropTypes.func.isRequired,
    defaultValues: PropTypes.shape({
      answer: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  questionStatus: PropTypes.string.isRequired,
  setQuestionStatus: PropTypes.func.isRequired,
}
