import ApiCore from './utilities/core'
import apiProvider from './utilities/provider'

const url = 'calendar'

const apiCalendar = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  delete: false,
  url,
})

apiCalendar.declineInterview = (token) =>
  apiProvider.patch(url, `${token}/decline`)

apiCalendar.getDataWithoutVerification = (token) =>
  apiProvider.getSingle(url, `${token}/expired`)

apiCalendar.resetInterview = (token) => apiProvider.patch(url, `${token}/reset`)

export default apiCalendar
