export const convertSnakeToStartCase = (text) =>
  text
    ?.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')

export const convertCamelToStartCase = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => ` ${word.toUpperCase()}`)
    .replace(/\s+/g, ' ')

export const formatCountryName = (countryName) => {
  if (!countryName) return ''
  const lowercaseWords = ['and', 'of', 'the']
  const formattedCountryName = countryName
    .toLowerCase()
    .split('_')
    .map((word, index) => {
      if (index === 0 || !lowercaseWords.includes(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word
    })
  return formattedCountryName.join(' ')
}

export const MAX_FILE_SIZE = 20000000

export const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
]

export const KEY_SUPPORTED_FORMATS = {
  pdf: 'application/pdf',
  dot: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  jpg: 'image/jpeg',
}
