import React from 'react'
import { Spinner } from 'react-bootstrap'
import FullLogo from '../assets/logos/full-logo.png'
import { ContentContainer, ImageContainer, MainContainer } from './styled'

export default function LoadingPage() {
  return (
    <MainContainer>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      <ContentContainer padding="0px">
        <Spinner
          animation="border"
          variant="secondary"
          style={{ height: '5rem', width: '5rem' }}
        />
      </ContentContainer>
    </MainContainer>
  )
}
