import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  CompanyDetailsDetailsCell,
  CompanyDetailsDetailsContent,
  CompanyDetailsDetailsRow,
} from '../styled'

function CompanyDetails({ location, size, website, industry }) {
  const { t } = useTranslation()

  const details = [
    { label: t('general.location'), content: location },
    { label: t('card.companySize'), content: size },
    { label: t('general.website'), content: <a href={website}>{website}</a> },
    { label: t('card.industry'), content: industry },
  ]

  return (
    <div className="row p-3">
      <div className="col-md-9 col-12">
        <table className="w-100">
          <tbody>
            {details.map(({ label, content }) => (
              <CompanyDetailsDetailsRow key={label}>
                <CompanyDetailsDetailsCell>{label} :</CompanyDetailsDetailsCell>
                <CompanyDetailsDetailsContent>
                  {content}
                </CompanyDetailsDetailsContent>
              </CompanyDetailsDetailsRow>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

CompanyDetails.propTypes = {
  location: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
}

export default CompanyDetails;
