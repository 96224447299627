import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  CareerColumn,
  CareerContainer,
  CareerDot,
  CareerFooter,
  CareerLine,
  CareerLink,
  CareerLinkContainer,
  CareerLocation,
  CareerStretch,
  CareerText,
} from '../styled'

export default function PositionCard({ position }) {
    function convertToNormalText(input) {
        return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
  return (
    <CareerContainer>
      <CareerStretch>
        <CareerColumn>
          <CareerColumn>
            <CareerText color="#7A858C" fontSize={13} fontWeight={400}>
              Department
            </CareerText>
            <CareerText fontSize={16} fontWeight={600}>
              {position?.title}
            </CareerText>
            <CareerLocation>
            {position?.city && (
                <>
                  {' '}
                  <CareerText fontSize={13} fontWeight={400}>
                    {convertToNormalText(position?.city)}
                  </CareerText>
                  <CareerDot />
                </>
              )}
              {position?.country && (
                <>
                  {' '}
                  <CareerText fontSize={13} fontWeight={400}>
                    {convertToNormalText(position?.country)}
                  </CareerText>
                  <CareerDot />
                </>
              )}
              <CareerText fontSize={13} fontWeight={400}>
                {convertToNormalText(position?.workplaceType)}
              </CareerText>
              <CareerDot />
              <CareerText fontSize={13} fontWeight={400}>
                {convertToNormalText(position?.employmentType)}
              </CareerText>
            </CareerLocation>
          </CareerColumn>
        </CareerColumn>
      </CareerStretch>
      <CareerFooter>
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <CareerText fontSize={13} fontWeight={600}>
            Open Date:{' '}
          </CareerText>
          <CareerText fontSize={13} fontWeight={400}>
            {position?.startDate}
          </CareerText>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <CareerText fontSize={13} fontWeight={600}>
            End Date:{' '}
          </CareerText>
          <CareerText fontSize={13} fontWeight={400}>
            {position?.endDate}
          </CareerText>
        </div>
      </CareerFooter>
      <CareerLine />
      <CareerLinkContainer>
        <Link to={`/job-application/${position?.invitationLink}`}>
          <CareerLink>View Position</CareerLink>
        </Link>
      </CareerLinkContainer>
    </CareerContainer>
  )
}

PositionCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  position: PropTypes.object.isRequired,
}
