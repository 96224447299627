/* eslint-disable no-console */
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useHMSActions,
  useHMSStore,
  selectRecordingState,
} from '@100mslive/react-sdk'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import { Spinner } from 'react-bootstrap'
import CustomModal from '../customModal'
import {
  InterviewButtonsContainer,
  InterviewVideoPreviewContainer,
} from '../styled'
import apiInterviewVersions from '../../services/api/interviewVersion'
import { ReactComponent as Mic } from '../../assets/svgs/mic.svg'
import { ReactComponent as Stop } from '../../assets/svgs/stop-circle.svg'
import theme from '../../constants/theme'
import PrimaryButton from '../buttons/PrimaryButton'

export default function RecordingSectionThirdParty({
  idOfCurrentQuestions,
  recordingStatus,
  setRecordingStatus,
  isLoading,
}) {
  const { t } = useTranslation()
  const message = t('interview.editResponseQuestion')
  const hmsActions = useHMSActions()
  const hmsStoreRecording = useHMSStore(selectRecordingState)
  localStorage.setItem('hmsStoreRecording', hmsStoreRecording.browser.state)
  const { interviewToken } = useParams()
  const [show, setShow] = useState(false)
  const webcamRef = useRef(null)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleStartRecording = () => {
    setRecordingStatus('loading')
    const data = {
      question_id: idOfCurrentQuestions,
      time: new Date().toISOString(),
      video_type: 'new',
    }
    apiInterviewVersions
      .logRecordingStarting({
        interviewToken,
        data,
      })
      .then(async () => {
        const params = {
          meetingURL: '',
          rtmpURLs: [],
          record: true,
        }
        try {
          await hmsActions.startRTMPOrRecording(params)
          const checkRecordingStatus = () => {
            localStorage.getItem('hmsStoreRecording')
            if (localStorage.getItem('hmsStoreRecording') === 'started') {
              setRecordingStatus('recording')
            } else {
              setTimeout(() => checkRecordingStatus(), 500)
            }
          }
          checkRecordingStatus()
        } catch (err) {
          console.error('failed to start RTMP/recording', err)
        }
      })
  }

  const handleStopRecording = async () => {
    setRecordingStatus('stopping')
    try {
      await hmsActions.stopRTMPAndRecording()
      setRecordingStatus('stopped')
    } catch (err) {
      console.error('failed to stop RTMP/recording', err)
    }
  }

  const editRecord = async () => {
    setShow(false)
    setRecordingStatus('loading')
    const data = {
      question_id: idOfCurrentQuestions,
      time: new Date().toISOString(),
      video_type: 'edit',
    }
    apiInterviewVersions
      .logRecordingStarting({
        interviewToken,
        data,
      })
      .then(async () => {
        const params = {
          meetingURL: '',
          rtmpURLs: [],
          record: true,
        }
        try {
          await hmsActions.startRTMPOrRecording(params)
          const checkRecordingStatus = () => {
            localStorage.getItem('hmsStoreRecording')
            if (localStorage.getItem('hmsStoreRecording') === 'started') {
              setRecordingStatus('recording')
            } else {
              setTimeout(() => checkRecordingStatus(), 500)
            }
          }
          checkRecordingStatus()
        } catch (err) {
          console.error('failed to start RTMP/recording', err)
        }
      })
  }

  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        handleSubmit={editRecord}
        message={message}
      />
      <InterviewVideoPreviewContainer>
        <div style={{ height: '480px', width: '100%' }}>
          <Webcam audio muted ref={webcamRef} height="100%" width="100%" />
        </div>
        <InterviewButtonsContainer>
          <PrimaryButton
            color={
              recordingStatus !== 'stopped' || isLoading
                ? theme.talentinoDarkerGray
                : theme.talentinoSpecialBlue
            }
            backgroundcolor={theme.talentinoWhiteBlue}
            hovercolor={theme.talentinoDarkerWhiteBlue}
            disabled={recordingStatus !== 'stopped' || isLoading}
            onClick={handleShow}
          >
            {t('interview.changeResponse')}
          </PrimaryButton>
          {(recordingStatus === 'notAnswered' ||
            recordingStatus === 'loading') && (
            <PrimaryButton
              backgroundcolor={
                recordingStatus === 'loading'
                  ? theme.talentinoDarkerBlue
                  : theme.talentinoBlue
              }
              disabled={recordingStatus === 'loading'}
              onClick={handleStartRecording}
            >
              {recordingStatus === 'notAnswered' && (
                <Mic style={{ marginRight: '5px' }} />
              )}
              {recordingStatus === 'loading' && (
                <Spinner
                  style={{ marginRight: '5px' }}
                  animation="border"
                  variant="secondary"
                  size="sm"
                />
              )}
              {t('interview.startRecording')}
            </PrimaryButton>
          )}
          {(recordingStatus === 'recording' || recordingStatus === 'stopping') && (
            <PrimaryButton
              backgroundcolor={recordingStatus === 'stopping' ? theme.talentinoDarkerRed :theme.talentinoRed}
              hovercolor={theme.talentinoDarkerRed}
              disabled={recordingStatus === 'stopping'}
              onClick={handleStopRecording}
            >
              {recordingStatus === 'recording' && (
                <Stop style={{ marginRight: '5px' }} />
              )}
              {recordingStatus !== 'recording' && (
                <Spinner
                  style={{ marginRight: '5px' }}
                  animation="border"
                  variant="secondary"
                  size="sm"
                />
              )}
              {t('interview.stopRecording')}
            </PrimaryButton>
          )}
        </InterviewButtonsContainer>
      </InterviewVideoPreviewContainer>
    </>
  )
}

RecordingSectionThirdParty.propTypes = {
  idOfCurrentQuestions: PropTypes.number.isRequired,
  recordingStatus: PropTypes.string.isRequired,
  setRecordingStatus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
