import ApiCore from './utilities/core'
import apiProvider from './utilities/provider'

const url = 'https://api.100ms.live/v2'

const apiHMS = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url,
})

apiHMS.getRoomData = async (managementToken, roomName) => { 
  const response = await apiProvider.post(`${url}/rooms`, {
    name: roomName,
    description: 'interview'
  }, {
    headers: {
      'Authorization': `Bearer ${managementToken}`
    }
  });
  return response;
}

apiHMS.getRoomCode = async (managementToken, roomId) => {
  const response = await apiProvider.post(`${url}/room-codes/room/${roomId}`, null, {
    headers: {
      'Authorization': `Bearer ${managementToken}`
    }
  });
  return response;
}

export default apiHMS
