import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import BaseRadio from './BaseRadio'

export default function InlineRadioInput({
  name,
  options,
  label,
  helperText,
  required,
  labelWidth,
  ...rest
}) {
  const { register } = useFormContext()
  return (
    <Form>
      {rest.prefix}
      {options?.map((option) => (
        <BaseRadio
          key={option.label}
          {...register(name, { required })}
          inline
          label={option.label}
          value={option.value}
          {...rest}
        />
      ))}
      {rest.postfix}
    </Form>
  )
}

InlineRadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  labelWidth: PropTypes.number,
}

InlineRadioInput.defaultProps = {
  helperText: '',
  required: false,
  labelWidth: 3,
}
