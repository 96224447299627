/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useLocation } from 'react-router-dom'
import { HMSPrebuilt } from '@100mslive/roomkit-react'

function InPersonInterview() {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const roomCode = queryParams.get('room-code')

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <HMSPrebuilt roomCode={roomCode} />
    </div>
  )
}

export default InPersonInterview
