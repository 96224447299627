import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { Typography } from '../styled'

export default function Input({
  name,
  label,
  type,
  placeholder,
  helperText,
  required,
  as,
  fontSize,
  ...rest
}) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Form.Group className="mb-2">
          {label ? (
            <Form.Label className="text-muted m-0">
              <Typography size={fontSize}>
                {label}
                {required && '*'}
              </Typography>
            </Form.Label>
          ) : null}
          <Form.Control
            isInvalid={!!fieldState.error}
            onChange={field.onChange}
            type={type}
            value={field.value}
            placeholder={placeholder}
            as={as}
            {...rest}
          />
          <Form.Text>
            {fieldState.error ? fieldState.error.message : helperText}
          </Form.Text>
        </Form.Group>
      )}
    />
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  as: PropTypes.string,
  fontSize: PropTypes.string,
}

Input.defaultProps = {
  placeholder: '',
  helperText: '',
  required: false,
  type: 'text',
  as: 'input',
  fontSize: '14px',
}
