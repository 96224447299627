import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import theme from '../constants/theme'

const Avatar = styled('div')(({ size, fontSize }) => ({
  fontSize: `${fontSize}px`,
  width: `${size}em`,
  height: `${size}em`,
  borderRadius: '50%',
  background: '#555555',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:after': {
    content: 'attr(data-label)',
    color: '#ffffff',
  },
}))

export const AvatarImg = styled('img')(({ size }) => ({
  height: `${size}em`,
  width: `${size}em`,
  backgroundColor: theme.gray300,
  padding: '3px',
  borderRadius: '50%',
}))

export default function ProfileAvatar({ name, picture, size, fontSize }) {
  const getInitials = (_name) => {
    const firstInitial = _name.split(' ')[0].charAt(0).toUpperCase()
    if (_name.split(' ').length === 1) {
      return `${firstInitial}`
    }
    const secondInitial = _name.split(' ')[1]?.charAt(0).toUpperCase()
    return `${firstInitial}${secondInitial}`
  }
  const getBackgroundColor = (_name) => {
    const label = getInitials(_name)
    const charCodeRed = label.charCodeAt(0)
    const charCodeGreen = label.charCodeAt(1) || charCodeRed
    const red = charCodeRed ** 7 % 200
    const green = charCodeGreen ** 7 % 200
    const blue = (red + green) % 200
    return { backgroundColor: `rgb(${red}, ${green}, ${blue})` }
  }
  if (picture) {
    return <AvatarImg src={picture} alt={name} size={size} />
  }
  if (name) {
    return (
      <Avatar
        data-label={getInitials(name)}
        style={getBackgroundColor(name)}
        size={size}
        fontSize={fontSize}
      />
    )
  }
  return <Avatar data-label="" />
}

ProfileAvatar.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  size: PropTypes.number,
  fontSize: PropTypes.number,
}

ProfileAvatar.defaultProps = {
  name: null,
  picture: null,
  size: 2.2,
  fontSize: 16,
}
