import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '../styled'
import theme from '../../constants/theme'
import AbstractForm from '../forms/AbstractForm'

function MultiChoiceQuestion({ question, index, setEliminatingAnswers }) {
  const inputs = [
    {
      name: `multiChoice`,
      label: `Q${index}: ${question.question}`,
      renderType: 'radio',
      type: 'checkbox',
      required: true,
      options: Object.keys(question.answer).map((a) => ({
        label: a,
        value: a,
      })),
      width: 12,
    },
  ]

  const handleFormWatch = (value) => {
    if (value.multiChoice !== undefined) {
      setEliminatingAnswers((prev) => [
        ...prev.filter((a) => a.id !== question.id),
        {
          id: question.id,
          answer: value.multiChoice.reduce(
            (accumulator, v) => ({ ...accumulator, [v]: true }),
            {},
          ),
        },
      ])
    }
  }

  return (
    <>
      {' '}
      <p className="mt-4 mb-3">
        <Typography
          backgroundColor={theme.cardBorder}
          color={theme.brandColorOrange}
          size={18}
        >
          {`Q${index}.`}
        </Typography>{' '}
        <Typography backgroundColor={theme.cardBorder} color={theme.inputField}>
          {question.question}
        </Typography>
      </p>
      <AbstractForm
        inputs={inputs}
        handleFormWatch={(value) => handleFormWatch(value)}
      />
    </>
  )
}

export default MultiChoiceQuestion

MultiChoiceQuestion.defaultProps = {
  setEliminatingAnswers: () => {},
  index: 0,
  question: PropTypes.shape({
    id: '',
    question: '',
    answer: {},
  }),
}

MultiChoiceQuestion.propTypes = {
  setEliminatingAnswers: PropTypes.func,
  index: PropTypes.number,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answer: PropTypes.object.isRequired,
  }),
}
