import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMachine } from '@xstate/react'
import { useReactMediaRecorder } from 'react-media-recorder'
import {
  ButtonsContainer,
  CameraButton,
  ContentContainer,
  FooterContainer,
  InstructionsContainer,
  InstructionsText,
  OutlineCameraButton,
  StyledHeading,
  StyledParagraph,
} from '../styled'
import {ReactComponent as CameraOff} from '../../assets/svgs/cameraOff.svg'
import {ReactComponent as CameraOn} from '../../assets/svgs/cameraOn.svg'
import interviewMachine from '../interviewMachine'
import apiInterviewVersions from '../../services/api/interviewVersion'
import storageApi from '../../services/firebaseStorage'
import WarningImage from '../../assets/images/carousel-1.png'
import RecordView from './recoordView'
import theme from '../../constants/theme'
import PrimaryButton from '../buttons/PrimaryButton'

const ButtonActions = {
  RESCHEDULE_INTERVIEW: 'rescheduleInterview',
  BEGIN_INTERVIEW: 'beginInterview',
  PERFORM_DEVICE_CHECK: 'performTechCheck',
  STOP_DEVICE_CHECK: 'stopTechCheck',
};

const getButtonSettings = ({ isTested, loading, reschedule, isFinished, isRecording, buttonsFunctions, t }) => ({
  [ButtonActions.RESCHEDULE_INTERVIEW]: {
    isVisible: isTested && reschedule && !isFinished,
    color: theme.talentinoSpecialBlue,
    backgroundcolor: theme.talentinoWhiteBlue,
    hovercolor: theme.talentinoDarkerWhiteBlue,
    onClick: buttonsFunctions.rescheduleInterview,
    text: t('preInterview.rescheduleInterview'),
  },
  [ButtonActions.BEGIN_INTERVIEW]: {
    isVisible: isTested && !loading,
    onClick: buttonsFunctions.handleSubmit,
    text: t('preInterview.startInterview'),
  },
  [ButtonActions.PERFORM_DEVICE_CHECK]: {
    isVisible: !isTested && !loading,
    onClick: buttonsFunctions.handleStartRecording,
    text: t('preInterview.test'),
  },
  [ButtonActions.STOP_DEVICE_CHECK]: {
    isVisible: loading,
    color: !isRecording && theme.talentinoSpecialBlue,
    backgroundcolor: isRecording ? theme.talentinoRed : theme.talentinoWhiteBlue,
    hovercolor: isRecording ? theme.talentinoDarkerRed : theme.talentinoDarkerWhiteBlue,
    onClick: buttonsFunctions.handleStopRecording,
    text: isRecording ? t('preInterview.stopTesting') : 'Getting results...',
    disabled: !isRecording && loading,
  },
});

function InterviewPrepFooter(props) {
  const { t } = useTranslation()
  const reschedule = true
  const buttonSettings = getButtonSettings({ ...props, t, reschedule });
  return (
    <FooterContainer>
      {Object.keys(ButtonActions).map((action) => {
        const { isVisible, color, ...buttonProps } = buttonSettings[ButtonActions[action]];
        return isVisible && (
          <PrimaryButton
            key={ButtonActions[action]}
            color={color || undefined}
            {...buttonProps}
          >
            {buttonProps.text}
          </PrimaryButton>
        );
      })}
    </FooterContainer>
  );
};

function TestVideo({ rescheduleInterview, rescheduleEnabled, isFinished }) {
  const { t } = useTranslation()
  const { interviewToken } = useParams()
  const [, send] = useMachine(interviewMachine, {
    services: {
      fetchInterview: () =>
        apiInterviewVersions.startInterview(interviewToken).then((res) => res),
    },
  })
  const navigate = useNavigate()
  const [askAllowMedia, setAskAllowMedia] = React.useState(false)
  const [isAllowVideo, setIsAllowVideo] = React.useState(false)
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({
      video: true,
      audio: true,
      blobPropertyBag: {
        type: 'video/webm',
      },
    })
  const [isRecording, setIsRecording] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isTested, setIsTested] = React.useState(false)

  const [message, setMessage] = React.useState(t('preInterview.testDevices'))
  const [secondMessage, setSecondMessage] = React.useState(
    t('preInterview.testCameraAndMic'),
  )

  const testUploadSpeed = async () => {
    setMessage(t('preInterview.testingUploadSpeed'))

    const createFile = () => {
      const size = 1024 * 1024
      const char = 'a'
      const content = char.repeat(size)
      const file = new Blob([content], { type: 'text/plain' })
      return file
    }

    const testFile = createFile()

    const testFilePath = `speed-test/${testFile.filename}`
    const speedInMbps = await storageApi.uploadTest(testFile, testFilePath)

    setMessage(`${speedInMbps.toFixed(2)} Mbps`)

    if (speedInMbps < 1) {
      setSecondMessage(t('preInterview.lowUploadSpeed'))
    } else if (speedInMbps < 8) {
      setSecondMessage(t('preInterview.mediumUploadSpeed'))
    } else {
      setSecondMessage(t('preInterview.acceptedUploadSpeed'))
    }

    let SpeedCategory

    if (speedInMbps > 8) {
      SpeedCategory = 'high'
    } else if (speedInMbps > 1) {
      SpeedCategory = 'medium'
    } else {
      SpeedCategory = 'low'
    }

    const data = {
      speed_value: speedInMbps,
      speed_category: SpeedCategory,
    }

    await apiInterviewVersions
      .logUploadSpeed({ interviewToken, data })
      .then(() => {
        setLoading(false)
        setIsTested(true)
      })
  }

  const handleStartRecording = () => {
    setLoading(true)
    setMessage('Testing...')
    setSecondMessage('')
    setIsRecording(true)
    startRecording()
  }
  const handleStopRecording = () => {
    setIsRecording(false)
    stopRecording()
    testUploadSpeed()
  }
  const handleAllowMedia = () => {
    setAskAllowMedia(true)
  }

  const handleSubmit = () => {
    localStorage.setItem('isReady', 'true')
    send('startInterview')
    navigate(0)
  }

  const videoRef = React.useRef(null)
  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { height: '100%', width: '100%' },
        audio: true,
      })
      .then((stream) => {
        setIsAllowVideo(true)
        const video = videoRef.current
        video.srcObject = stream
        video.play()
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }

  const buttonsFunctions = {
    rescheduleInterview,
    handleSubmit,
    handleStartRecording,
    handleStopRecording,
  }

  React.useEffect(() => {
    if (askAllowMedia) getVideo()
  }, [videoRef, askAllowMedia])

  return (
    <>
      <InstructionsContainer>
        <InstructionsText>
          {t('preInterview.testDevicesTitle')}
        </InstructionsText>
      </InstructionsContainer>

      <ContentContainer>
        <div
          className="max-width-container d-flex flex-column justify-content-center align-items-center"
          style={{ height: '303px' }}
        >
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ position: 'relative', height: '303px' }}
          >
            {!askAllowMedia ? (
              <img src={WarningImage} alt="test devices" height="303px" />
            ) : (
              <div>
                {status === 'idle' ? (
                  <video
                    ref={videoRef}
                    height="303px"
                    className="rounded"
                    muted
                  />
                ) : (
                  <RecordView
                    isRecording={isRecording}
                    previewStream={previewStream}
                    mediaBlobUrl={mediaBlobUrl}
                  />
                )}
              </div>
            )}
            {status !== 'stopped' && (
              <ButtonsContainer id="devices-btns" className=" d-block p-3">
                {!isAllowVideo ? (
                  <CameraButton
                    id="camera-btn-off"
                    className="btn btn-danger"
                    onClick={handleAllowMedia}
                    style={{
                      position: 'absolute',
                      bottom: '20px',
                      right: '40px',
                      backgroundColor: theme.talentinoRed,
                    }}
                  >
                    <CameraOff />
                  </CameraButton>
                ) : (
                  <OutlineCameraButton
                    id="camera-btn"
                    className="btn btn-outline-light"
                    style={{
                      position: 'absolute',
                      bottom: '20px',
                      right: '40px',
                    }}
                  >
                    <CameraOn />
                  </OutlineCameraButton>
                )}
              </ButtonsContainer>
            )}
          </div>
          <StyledHeading>{message}</StyledHeading>
          <StyledParagraph color={isTested && theme.talentinoDarkerGray}>
            {secondMessage}
            {loading && <Spinner animation="border" variant="secondary" />}
          </StyledParagraph>
        </div>
        <InterviewPrepFooter
          isTested={isTested}
          loading={loading}
          isRecording={isRecording}
          rescheduleEnabled={rescheduleEnabled}
          isFinished={isFinished}
          buttonsFunctions={buttonsFunctions}
        />
      </ContentContainer>
    </>
  )
}

export default TestVideo

TestVideo.propTypes = {
  rescheduleInterview: PropTypes.func,
  rescheduleEnabled: PropTypes.bool,
  isFinished: PropTypes.bool,
}

TestVideo.defaultProps = {
  rescheduleInterview: () => {},
  rescheduleEnabled: false,
  isFinished: false,
}
