import React from 'react'
import PropTypes from 'prop-types'
import AbstractForm from '../forms/AbstractForm'
import { Typography } from '../styled'
import theme from '../../constants/theme'

function RangeQuestion({ question, index, setEliminatingAnswers }) {
  const inputs = [
    {
      name: `from`,
      label: `from`,
      renderType: 'number',
      type: 'number',
      min: 0,
      required: true,
      width: 6,
    },
    {
      name: `to`,
      label: `to`,
      renderType: 'number',
      type: 'number',
      min: 0,
      required: true,
      width: 6,
    },
  ]

  const handleFormWatch = (value) => {
    if (value.from !== undefined && value.to !== undefined)
      setEliminatingAnswers((prev) => [
        ...prev.filter((a) => a.id !== question.id),
        {
          id: question.id,
          answer: { start: Number(value?.from), end: Number(value?.to) },
        },
      ])
  }

  return (
    <>
      {' '}
      <p className="mt-4 mb-3">
        <Typography
          backgroundColor={theme.cardBorder}
          color={theme.brandColorOrange}
          size={18}
        >
          {`Q${index}.`}
        </Typography>{' '}
        <Typography backgroundColor={theme.cardBorder} color={theme.inputField}>
          {question.question}
        </Typography>
      </p>
      <AbstractForm
        inputs={inputs}
        handleFormWatch={(value) => handleFormWatch(value)}
      />
    </>
  )
}

export default RangeQuestion

RangeQuestion.defaultProps = {
  setEliminatingAnswers: () => {},
  index: 0,
  question: PropTypes.shape({
    id: '',
    question: '',
    answer_type: '',
    answer: {},
    show: false,
  }),
}

RangeQuestion.propTypes = {
  setEliminatingAnswers: PropTypes.func,
  index: PropTypes.number,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answer_type: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answer: PropTypes.object.isRequired,
    show: PropTypes.bool,
  }),
}
