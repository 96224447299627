import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMachine } from '@xstate/react'
import FullLogo from '../../assets/logos/full-logo.png'
import VoiceInstructionsImage from '../../assets/images/carousel-1.png'
import ConnectionInstructionsImage from '../../assets/images/carousel-2.png'
import VideoInstructionsImage from '../../assets/images/carousel-3.png'
import {
  BackButton,
  ButtonContainer,
  CarouselButton,
  CarouselContainer,
  CarouselText,
  CarouselTitle,
  ContentContainer,
  CountDownBoxWrapper,
  CountdownCard,
  CountdownCardBody,
  CountdownCardHeader,
  FooterContainer,
  ImageContainer,
  InstructionsContainer,
  InstructionsText,
  MainContainer,
  StepsContainer,
  StepsText,
} from '../styled'
import PrimaryButton from '../buttons/PrimaryButton'
import theme from '../../constants/theme'
import interviewMachine from '../interviewMachine'
import apiInterviewVersions from '../../services/api/interviewVersion'

function CountdownItem({ label, value }) {
  return (
    <CountdownCard>
      <CountdownCardHeader>{label}</CountdownCardHeader>
      <CountdownCardBody>{value}</CountdownCardBody>
    </CountdownCard>
  )
}

CountdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

function Timer({ interviewTime, setShowCounter }) {
  const { t } = useTranslation()
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds <= 0) {
        if (minutes <= 0) {
          if (hours <= 0) {
            if (days <= 0) {
              clearInterval(countdownInterval)
              setShowCounter(false)
            } else {
              setDays(days - 1)
              setHours(23)
              setMinutes(59)
              setSeconds(59)
            }
          } else {
            setHours(hours - 1)
            setMinutes(59)
            setSeconds(59)
          }
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(countdownInterval)
    }
  })

  useEffect(() => {
    const time = new Date(`${interviewTime?.toString()}`)
    const now = new Date()
    const remainingTime = time - now
    if (remainingTime < 0) {
      setShowCounter(false)
    }
    setDays(Math.floor(remainingTime / (1000 * 60 * 60 * 24)))
    setHours(
      Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    )
    setMinutes(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)))
    setSeconds(Math.floor((remainingTime % (1000 * 60)) / 1000))
  }, [interviewTime])
  return (
    <CountDownBoxWrapper>
      <h2
        style={{
          color: '#3D3F46',
          fontSize: '22px',
          fontWeight: '600',
          marginBottom: '24px',
        }}
      >
        {t('preInterview.yourInterviewStarts')}{' '}
      </h2>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ gap: '2px' }}
      >
        <CountdownItem label={t('preInterview.days')} value={days} />
        <CountdownItem label={t('preInterview.hours')} value={hours} />
        <CountdownItem label={t('preInterview.minutes')} value={minutes} />
        <CountdownItem label={t('preInterview.seconds')} value={seconds} />
      </div>
    </CountDownBoxWrapper>
  )
}

Timer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  interviewTime: PropTypes.string,
  setShowCounter: PropTypes.func.isRequired,
}

function InPersonInterviewInstructions({ setReadInstructions }) {
  const { t } = useTranslation()
  const [carouselSelected, setCarouselSelected] = React.useState(1)
  const carouselItems = [
    {
      image: VoiceInstructionsImage,
      index: t('preInterview.first'),
      title: t('preInterview.voiceInstructions'),
      content: t('preInterview.sureMicWorks'),
    },
    {
      image: ConnectionInstructionsImage,
      index: t('preInterview.second'),
      title: t('preInterview.connectionInstructions'),
      content: t('preInterview.sureInternetStable'),
    },
    {
      image: VideoInstructionsImage,
      index: t('preInterview.third'),
      title: t('preInterview.videoInstructions'),
      content: t('preInterview.simpleBackground'),
    },
  ]
  const handleCarouselprev = () => {
    setCarouselSelected((prev) => prev - 1)
  }
  const handleCarouselNext = () => {
    if (carouselSelected < 3) {
      setCarouselSelected((prev) => prev + 1)
    } else {
      setReadInstructions(true)
    }
  }
  return (
    <>
      <InstructionsContainer>
        <InstructionsText>{t('preInterview.instructions')}</InstructionsText>
      </InstructionsContainer>
      <StepsContainer>
        <StepsText>
          {t('preInterview.beforeInterview')}{' '}
          <span style={{ fontWeight: '600' }}>
            {t('preInterview.threeEssentialSteps')}
          </span>{' '}
          {t('preInterview.toPrepare')}
        </StepsText>
      </StepsContainer>
      <ContentContainer>
        <CarouselContainer>
          <img
            height="303px"
            src={carouselItems[carouselSelected - 1].image}
            alt=""
          />
          <CarouselTitle>
            <span style={{ color: theme.talentinoBlue }}>
              {carouselItems[carouselSelected - 1].index},
            </span>{' '}
            {carouselItems[carouselSelected - 1].title}
          </CarouselTitle>
          <CarouselText>
            {carouselItems[carouselSelected - 1].content}
          </CarouselText>
          <ButtonContainer>
            <CarouselButton
              selected={carouselSelected === 1}
              onClick={() => setCarouselSelected(1)}
            />
            <CarouselButton
              selected={carouselSelected === 2}
              onClick={() => setCarouselSelected(2)}
            />
            <CarouselButton
              selected={carouselSelected === 3}
              onClick={() => setCarouselSelected(3)}
            />
          </ButtonContainer>
        </CarouselContainer>
        <FooterContainer>
          <BackButton
            carouselSelected={carouselSelected}
            onClick={handleCarouselprev}
          >
            {t('general.back')}
          </BackButton>
          <PrimaryButton
            backgroundcolor={theme.talentinoBlue}
            hovercolor={theme.talentinoDarkerBlue}
            width="150px"
            onClick={handleCarouselNext}
          >
            {t('pages.nextQuestion')}
          </PrimaryButton>
        </FooterContainer>
      </ContentContainer>
    </>
  )
}

InPersonInterviewInstructions.propTypes = {
  setReadInstructions: PropTypes.func.isRequired,
}

function PreInPersonInterview({
  interviewTime,
  setShowCounter,
  showCounter,
}) {
  const [readInstructions, setReadInstructions] = useState(false)
  const { interviewToken } = useParams()
  const [, send] = useMachine(interviewMachine, {
    services: {
      fetchInterview: () =>
        apiInterviewVersions.startInterview(interviewToken).then((res) => res),
    },
  })
  const navigate = useNavigate()
  useEffect(() => {
    if (!showCounter) {
      send('startInterview')
      navigate(0)
    }
  }, [showCounter])

  return (
    <MainContainer>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      {!readInstructions ? (
        <InPersonInterviewInstructions
          setReadInstructions={setReadInstructions}
        />
      ) : (
        <ContentContainer>
          <Timer
            interviewTime={interviewTime}
            setShowCounter={setShowCounter}
          />
        </ContentContainer>
      )}
    </MainContainer>
  )
}

PreInPersonInterview.propTypes = {
  interviewTime: PropTypes.string.isRequired,
  setShowCounter: PropTypes.func.isRequired,
  showCounter: PropTypes.bool.isRequired,
}


export default PreInPersonInterview
