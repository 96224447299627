/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types';
import theme from '../constants/theme';

function ProgressBar({ bgcolor, progress, height, leftText }) {
  const ParentDiv = {
    height,
    width: '100%',
    backgroundColor: theme.talentinoProgressbarParentColor,
    borderRadius: '50px',
    padding: '1px',
  }
  const ChildDiv = {
    height: '100%',
    width: `${(progress / leftText) * 100}%`,
    // width: '10%',
    backgroundColor: bgcolor,
    borderRadius: '5px 0 0 5px',
    textAlign: 'right',
    paddingRight: '20px',
    zIndex: 100,
  }

  return (
    <div style={ParentDiv} className="d-flex">
      <div style={ChildDiv}/>
      <div />
      <div style={{ float: 'right' }}/>
    </div>
  )
}

export default ProgressBar

ProgressBar.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  leftText: PropTypes.number.isRequired,
};