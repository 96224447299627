import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as ExclamationCircle } from '../assets/svgs/exclamationCircle.svg'
import theme from '../constants/theme'

export default function CustomModal({
  show,
  handleClose,
  handleSubmit,
  message,
}) {
  const { t } = useTranslation()
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body
        style={{
          border: 'none',
          height: '300px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className="icon-container"
          style={{
            alignItems: 'center',
            color: theme.talentinoSpecialBlue,
          }}
        >
          <ExclamationCircle style={{ width: '40px', height: '40px' }} />
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: '600',
            color: theme.talentinoSpecialBlue,
            padding: '24px',
            width: '350px',
          }}
        >
          {message}
        </div>
        <div
          className="button-container"
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ gap: '10px', marginTop: '10px' }}
          >
            <button
              onClick={handleClose}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px 16px',
                height: '36px',
                width: '124px',
                borderRadius: '2px',
                background: 'transparent',
                border: '1px solid #909DA5',
                color: '#3D3F46',
                fontSize: '12px',
                fontWeight: '500',
              }}
              type="button"
            >
              {t('general.back')}
            </button>
            <button
              onClick={handleSubmit}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px 16px',
                height: '36px',
                width: '124px',
                borderRadius: '2px',
                background: theme.talentinoSpecialBlue,
                border: 'none',
                color: theme.white,
                fontSize: '12px',
                fontWeight: '500',
              }}
              type="button"
            >
              {t('general.edit')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

CustomModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}
