export const months = [
  { en: 'Jan', ar: 'يناير', gr: 'Jan' },
  { en: 'Feb', ar: 'فبراير', gr: 'Feb' },
  { en: 'Mar', ar: 'مارس', gr: 'Mar' },
  { en: 'Apr', ar: 'أبريل', gr: 'Apr' },
  { en: 'May', ar: 'مايو', gr: 'Mai' },
  { en: 'Jun', ar: 'يونيو', gr: 'Jun' },
  { en: 'Jul', ar: 'يوليو', gr: 'Jul' },
  { en: 'Aug', ar: 'أغسطس', gr: 'Aug' },
  { en: 'Sep', ar: 'سبتمبر', gr: 'Sep' },
  { en: 'Oct', ar: 'أكتوبر', gr: 'Okt' },
  { en: 'Nov', ar: 'نوفمبر', gr: 'Nov' },
  { en: 'Dec', ar: 'ديسمبر', gr: 'Dez' },
]

export const days = [
  { en: 'Sun', ar: 'الأحد', gr: 'Sonntag' },
  { en: 'Mon', ar: 'الاثنين', gr: 'Montag' },
  { en: 'Tue', ar: 'الثلاثاء', gr: 'Dienstag' },
  { en: 'Wed', ar: 'الأربعاء', gr: 'Mittwoch' },
  { en: 'Thu', ar: 'الخميس', gr: 'Donnerstag' },
  { en: 'Fri', ar: 'الجمعة', gr: 'Freitag' },
  { en: 'Sat', ar: 'السبت', gr: 'Samstag' },
]
