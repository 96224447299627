import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import JobApplication from './jobApplication'
import JobApplicationForm from './jobApplicationForm'
import apiCompanyPositions from '../services/api/companyPosition'
import NotificationMessagePage from '../components/notificationMessagePage'

function Application() {
  const { t } = useTranslation()
  const { token } = useParams()
  const [isInternalHiring, setIsInternalHiring] = useState(false)
  const [renderType, setRenderType] = useState('loading')
  const errorMessage = useRef('')
  const position = useRef({})
  const company = useRef({})

  const handleApplyClick = () => {
    setRenderType('form')
  }
  const handleBackClick = () => {
    setRenderType('jobApplication')
  }
  useEffect(() => {
    apiCompanyPositions
      .getSingleWithToken(token)
      .then((res) => {
        position.current = res
        company.current = res.company
        setIsInternalHiring(res.isInternalApply)
        setRenderType('jobApplication')
      })
      .catch((res) => {
        if (res?.response?.status === 400) {
          errorMessage.current = t('pages.positionIsClosed')
        } else if (res?.response?.status === 401)
          errorMessage.current = t('pages.unauthorized')
        else errorMessage.current = t('pages.pageNotFound')
        setRenderType('error')
      })
  }, [])

  switch (renderType) {
    case 'loading':
      return (
        <NotificationMessagePage
          messageType="loading"
          message={t('pages.loading')}
        />
      )
    case 'jobApplication':
      return (
        <JobApplication
          onApplyClick={handleApplyClick}
          company={company.current}
          position={position.current}
        />
      )
    case 'form':
      return (
        <JobApplicationForm
          onBackClick={handleBackClick}
          company={company.current}
          position={position.current}
          isInternalHiring={isInternalHiring}
        />
      )
    case 'error':
      return (
        <NotificationMessagePage
          messageType="error"
          message={errorMessage.current}
        />
      )
    default:
      return null
  }
}

export default Application
