import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FullLogo from '../assets/logos/full-logo.png'
import {
  ContentContainer,
  ImageContainer,
  MainContainer,
  NotificationBox,
  Typography,
} from './styled'
import theme from '../constants/theme'
import PrimaryButton from './buttons/PrimaryButton'

export default function SmallerScreenNotification({
  rescheduleInterview,
  rescheduleEnabled,
  isFinished,
}) {
  const { t } = useTranslation()
  return (
    <MainContainer backgroundColor={theme.talentinoSkyBlue}>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      <ContentContainer>
        <NotificationBox minWidth="auto" color={theme.white}>
          <Typography size="40px" color={theme.danger} bold>
            ⚠
          </Typography>
          <div className="d-flex justify-content-center align-items-center">
            <Typography className="fs-6" style={{ textAlign: 'center' }}>
              This application is not compatible with smaller screens or mobile
              devices. Please {rescheduleInterview && 'reschedule or'} access
              the application from your computer.
            </Typography>
          </div>
          <div className="my-3">
            <Typography className="fs-6 text-muted">
              Please note that the link may expire after a certain period and
              may not work.
            </Typography>
          </div>
          {rescheduleEnabled && !isFinished && (
            <PrimaryButton
              color={theme.talentinoSpecialBlue}
              backgroundcolor={theme.talentinoWhiteBlue}
              hovercolor={theme.talentinoDarkerWhiteBlue}
              onClick={rescheduleInterview}
            >
              {t('preInterview.rescheduleInterview')}
            </PrimaryButton>
          )}
        </NotificationBox>
      </ContentContainer>
      <p
        style={{
          color: theme.talentinoDarkerGray,
          fontSize: '13px',
          fontWeight: '400',
        }}
      >
        © {new Date().getFullYear()}
        <span style={{ fontWeight: '600' }}> {t('footer.TalentinoAI')}</span>.{' '}
        {t('footer.developedBy')}{' '}
        <span style={{ fontWeight: '600' }}>{t('footer.AreteGlobal')}</span>
      </p>
    </MainContainer>
  )
}

SmallerScreenNotification.propTypes = {
  rescheduleInterview: PropTypes.func,
  rescheduleEnabled: PropTypes.bool,
  isFinished: PropTypes.bool,
}
SmallerScreenNotification.defaultProps = {
  rescheduleInterview: () => {},
  rescheduleEnabled: false,
  isFinished: false,
}
