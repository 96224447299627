import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button'

const BaseOutlineButton = styled(Button)(
  ({
    hoverbackgroundcolor,
    disablecolor,
    color,
    fontSize = '1rem',
    hoverColor = '#FFFFFF',
    backgroundColor = '#ffffff',
    textDecoration = 'none',
  }) => ({
    fontFamily: 'PoppinsMedium',
    textTransform: 'none',
    textDecoration,
    color,
    fontSize,
    backgroundColor,
    borderColor: hoverbackgroundcolor,
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: hoverbackgroundcolor,
      borderColor: hoverbackgroundcolor,
      color: hoverColor,
    },
    '&:disabled': {
      backgroundColor,
      borderColor: disablecolor,
      color: disablecolor,
    },
    '&:focus': {
      backgroundColor: hoverbackgroundcolor,
      borderColor: hoverbackgroundcolor,
      color: hoverColor,
    },
  }),
)

export default BaseOutlineButton
