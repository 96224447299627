import apiProvider from './utilities/provider'

const url = 'internal-applications'

const apiInternalApplications = {
  applyForPositionInternally: (token, data) =>
    apiProvider.post(`${url}/apply/${token}`, data),
}

export default apiInternalApplications
