import React from 'react'
import PropTypes from 'prop-types'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const RichTextEditor = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  )
  const [updated, setUpdated] = React.useState(false)

  React.useEffect(() => {
    if (!updated) {
      const defaultValue = value || ''
      const blocksFromHtml = htmlToDraft(defaultValue)
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      )
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }
  }, [value])

  const onEditorStateChange = (state) => {
    setUpdated(true)
    setEditorState(state)

    return onChange(draftToHtml(convertToRaw(state.getCurrentContent())))
  }

  return (
    <Editor
      spellCheck
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      {...rest}
      ref={ref}
    />
  )
})

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  editorStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  wrapperStyle: PropTypes.object,
}

RichTextEditor.defaultProps = {
  onChange: () => {},
  editorStyle: { padding: '0 8px' },
  wrapperStyle: {
    border: '1px solid #CED4DA',
    borderRadius: '5px',
  },
}

export default RichTextEditor
