const theme = {
  // colors
  navigationLinksDefault: '#ffffff',
  cardBorder: '#137FC3',
  white: '#ffffff',
  inputField: '#495057',
  defaultDisabledBorderColor: '#CED4DA',
  boxShadow: '#12263f08',
  sidebarCollapsedWidth: '4.375rem',
  headerHeight: '3.750rem',
  gray300: '#b3bbd3',
  headersMiniTitle: '#076698',
  // gray100: '#212529',
  headerItemColor: '#555b6d',
  headerItemColorHover: '#7b839c',
  hintsNotifications: '#3D3F46',
  footerBg: '#f2f2f5',
  footerSpanColor: '#74788d',
  mainContentBg: '#f8f8fb',
  brandColorOrange: '#076698',
  brandColorLightOrange: '#137FC3',
  brandColorCreamy: '#BBCCD8',
  success: '#17B179',
  danger: '#DB504A',
  info: '#00A9E6',
  darkBlue: '#6c757d',
  talentinoBlue: '#137FC3',
  talentinoDisabledBlue: '#BFD7E4',
  talentinoDisabledDarkerBlue: '#9FBAC9',
  talentinoLightBlue: '#137FC3',
  talentinoDarkerBlue: '#1068A0',
  talentinoWhiteBlue: '#E5EAEF',
  talentinoDarkerWhiteBlue: '#C4CFD4',
  talentinoSpecialBlue: '#076698',
  talentinoSpecialDarkBlue: '#094c70',
  talentinoRed: '#A93C38',
  talentinoDarkerRed: '#8B302E',
  talentinoGreen: '#0D8653',
  talentinoDarkerGreen: '#0B7044',
  talentinoGray: '#808080',
  talentinoBlack: '#000000',
  talentinoDarkerGray: '#7A858C',
  talentinoSkyBlue: '#C9D6E1',
  talentinoOfWhite: '#f5f5f5',
  talentinoProgressbarParentColor: '#EAEAEA',
  gridGutterWidth: '1.5rem',
  sidebarWidth: '12rem',
  sidebarWidthSm: '4.375rem',
  footerHeight: '2.625rem',
  menuItemSpacing: '0.75rem',

  // fonts
  defaultFontSize: '0.8125rem',

  defaultBackground: '#ffffff  0% 0% no-repeat padding-box',
  whiteButtonBackground: '#f5f5f5 0% 0% no-repeat padding-box',

  gradientBackground:
    'transparent linear-gradient(0deg, #3927514D 0%, #80808000 100%) 0% 0% no-repeat padding-box',
}

export default theme
