/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FormProvider, useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import * as jose from 'jose'
import ProgressBar from '../components/progressBar'
import FullLogo from '../assets/logos/full-logo.png'
import { ReactComponent as TimeCircle } from '../assets/svgs/timeCircle_blue.svg'
import Input from '../components/forms/Input'
import apiInterviewVersions from '../services/api/interviewVersion'
import theme from '../constants/theme'
import {
  ContentContainer,
  ImageContainer,
  InterviewQuestionTag,
  InterviewQuestionText,
  InterviewQuestionsContainerBox,
  InterviewQuestionsTextContainer,
  InterviewTimerBox,
  InterviewTimerText,
  MainContainer,
  QuestionsProgressCard,
  QuestionsProgressContainer,
  QuestionsProgressText,
  QuestionsProgressTitle,
  QuestionsProgressTitleContainer,
  Separator,
} from '../components/styled'
import LoadingPage from '../components/loadingPage'
import SpeechToText from '../components/preInterviewComponents/speechToText'
import PrimaryButton from '../components/buttons/PrimaryButton'

function TextInterviewV2() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { interviewToken } = useParams()
  const versionId = jose.decodeJwt(interviewToken).interview_version_id
  const [question, setQuestion] = useState('')
  const [questionsNumber, setQuestionsNumber] = useState(1)
  const [questionProgress, setQuestionProgress] = useState(1)
  const [idOfCurrentQuestions, setIdOfCurrentQuestions] = useState()
  const [questionStatus, setQuestionStatus] = useState('notAnswered')
  const [minutesInterview, setMinutesInterview] = useState(15)
  const [secondsInterview, setSecondsInterview] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [transcribedText, setTranscribedText] = useState('')
  const resetTranscriptRef = useRef(null)

  const schema = Yup.object().shape({
    answer: Yup.string().required(),
  })

  const defaultValues = {
    answer: '',
  }

  const method = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const handleSubmitAnswer = (data) => {
    setIsLoading(true)
    apiInterviewVersions
      .updateQuestionByCandidate({
        interviewToken,
        questionId: idOfCurrentQuestions,
        data: { answer: data.answer, versionId, used: true },
      })
      .then((sentQuestion) => {
        if (sentQuestion) {
          if (questionProgress+1 === questionsNumber) {
            navigate(0)
          } else
            apiInterviewVersions.getNextQuestion(interviewToken).then((res) => {
              setQuestion(res.question.toString())
              setQuestionProgress(questionProgress + 1)
              setIdOfCurrentQuestions(res.id)
              setQuestionStatus('notAnswered')
              setTranscribedText('')
              method.reset()
              return sentQuestion
            })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleResetTranscript = (resetTranscript) => {
    resetTranscriptRef.current = resetTranscript
  }

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (secondsInterview > 0) {
        setSecondsInterview(secondsInterview - 1)
      }
      if (secondsInterview === 0) {
        if (minutesInterview === 0) {
          clearInterval(countdownInterval)

          navigate(0)
        } else {
          setMinutesInterview(minutesInterview - 1)
          setSecondsInterview(59)
        }
      }
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  })

  useEffect(() => {
    apiInterviewVersions.startInterview(interviewToken).then((data) => {
      if (data.type.toLowerCase() === 'video')
        navigate(`/interview/${interviewToken}`)
      if (data && data.remainingQuestion) {
        setQuestion(data.question.toString())

        setIdOfCurrentQuestions(data.id)
        setQuestionStatus('notAnswered')
        const duration = data.duration.split('.')[0].split(':')
        setMinutesInterview(duration[1])
        setSecondsInterview(duration[2])
      } else {
        navigate(0)
      }
    })
    apiInterviewVersions
      .getNumberOfQuestionsByInterview(interviewToken)
      .then((res) => {
        if (res.progress < 2) {
          setQuestionProgress(1)
        } else {
          setQuestionProgress(res.progress)
        }
        setQuestionsNumber(res.questions)
      })
  }, [])

  useEffect(() => {
    method.reset({
      answer: transcribedText,
    })
  }, [transcribedText])

  useEffect(() => {
    if (resetTranscriptRef.current) {
      resetTranscriptRef.current()
    }
  }, [idOfCurrentQuestions])

  function getBrowser() {
    const { userAgent } = navigator
    let browser = 'unknown'
    if (userAgent.includes('Firefox')) {
      browser = 'Firefox'
    } else if (userAgent.includes('Chrome')) {
      browser = 'Chrome'
    }
    return browser
  }
  const isRecorderDisabled =
    getBrowser() !== 'Firefox'
      ? window.webkitSpeechRecognition === undefined
      : false

  if (!question) return <LoadingPage />

  return (
    <MainContainer>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      <ContentContainer
        justifyContent="auto"
        marginTop="50px"
        width="100vw"
        padding="0px"
      >
        <Row className="w-100">
          <Col xs={12} md={8} lg={9}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                marginBottom: '10px',
                padding: '16px',
              }}
            >
              <InterviewQuestionsContainerBox>
                  <FormProvider className="w-100" {...method}>
                    <Form
                      className="w-100"
                      onSubmit={method.handleSubmit(handleSubmitAnswer)}
                    >
                <InterviewQuestionTag>
                {t('interview.question')}:{' '}
                  <span
                    style={{
                      color: theme.talentinoBlue,
                    }}
                  >
                    {questionProgress}
                  </span>
                </InterviewQuestionTag>
                <InterviewQuestionsTextContainer>
                  <InterviewQuestionText>
                  {question}
                  </InterviewQuestionText>
                  {questionProgress === questionsNumber ? (
                    <PrimaryButton
                      backgroundcolor={theme.talentinoGreen}
                      hovercolor={theme.talentinoDarkerGreen}
                      width="90px"
                      disabled={questionStatus !== 'stopped'}
                      type='submit'
                    >
                      {t('general.submit')}
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      backgroundcolor={
                        questionStatus !== "recording"
                          ? theme.talentinoBlue
                          : theme.talentinoDisabledBlue
                      }
                      hovercolor={
                        questionStatus !== "recording"
                          ? theme.talentinoDarkerBlue
                          : theme.talentinoDisabledBlue
                      }
                      width="90px"
                      disabled={questionStatus === "recording"}
                      type='submit'
                    >
                      {t('pages.nextQuestion')}
                    </PrimaryButton>
                  )}
                </InterviewQuestionsTextContainer>
                    <Row className="w-100">
                      <Input
                        label={t('pages.answer')}
                        name="answer"
                        placeholder={t('pages.yourAnswer')}
                        as="textarea"
                        type="input"
                        rows={4}
                        disabled={
                          isRecorderDisabled || questionStatus === 'recording'
                        }
                      />
                    </Row>
                  </Form>
                </FormProvider>
              </InterviewQuestionsContainerBox>
              {getBrowser() !== 'Firefox' && (
                    <SpeechToText
                      onResetTranscript={handleResetTranscript}
                      setTranscribedText={setTranscribedText}
                      transcribedText={transcribedText}
                      isRecorderDisabled={isRecorderDisabled}
                      isLoading={isLoading}
                      method={method}
                      questionStatus={questionStatus}
                      setQuestionStatus={setQuestionStatus}
                    />
                  )}
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <QuestionsProgressCard>
              <QuestionsProgressTitleContainer>
                <QuestionsProgressTitle>
                {t('interview.interviewTimeWithTalentino')}
                </QuestionsProgressTitle>
              </QuestionsProgressTitleContainer>
              <Separator />
              <InterviewTimerBox>
                <InterviewTimerText>
                  <TimeCircle style={{ width: '20px', height: '20px' }} />
                  {minutesInterview} :{' '}
                  {secondsInterview < 10
                    ? `0${secondsInterview}`
                    : secondsInterview}
                </InterviewTimerText>
              </InterviewTimerBox>
            </QuestionsProgressCard>
            <QuestionsProgressCard>
              <QuestionsProgressTitleContainer>
                <QuestionsProgressTitle>
                {t('interview.questionProgress')}
                </QuestionsProgressTitle>
              </QuestionsProgressTitleContainer>
              <Separator />
              <QuestionsProgressContainer>
                <QuestionsProgressText>
                {t('interview.questions')}{' '}
                  <span
                    style={{
                      color: theme.talentinoBlue,
                    }}
                  >
                    ({questionProgress}/{questionsNumber})
                  </span>
                </QuestionsProgressText>
                <ProgressBar
                  bgcolor={theme.talentinoBlue}
                  progress={questionProgress}
                  height={10}
                  leftText={questionsNumber}
                />
              </QuestionsProgressContainer>
            </QuestionsProgressCard>
          </Col>
        </Row>
      </ContentContainer>
    </MainContainer>
  )
}

export default TextInterviewV2
