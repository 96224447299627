import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import CalendarPicker from '../components/calendar/CalendarPicker'
import JobDetails from '../components/card/JobDetails'
import CompanyDetails from '../components/card/CompanyDetails'
import apiCalendar from '../services/api/calendar'
import {
  ButtonContainer,
  CalendarCard,
  CalendarLogoContainer,
  CardHeader,
  Clock,
  ImageContainer,
  LogoImage,
  MainContainer,
  Separator,
  StyledCol,
  StyledColFlex,
  StyledContainer,
  StyledRow,
  StyledRowAuto,
  Title,
} from '../components/styled'
import FullLogo from '../assets/logos/full-logo.png'
import defaultLogo from '../assets/mini-logo.png'
import LoadingPage from '../components/loadingPage'
import { formatCountryName } from '../utilities/stringUtilities'
import NotificationBoxPage from '../components/notificationBoxPage'
import PrimaryButton from '../components/buttons/PrimaryButton'
import theme from '../constants/theme'

dayjs.extend(utc)
dayjs.extend(timezone)

function Calendar() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token } = useParams()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const role = queryParams.get('role')
  const roomCode = queryParams.get('room-code')

  const msgRef = useRef(null)
  const [data, setData] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const [invalidLink, setInvalidLink] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState(null)
  const [allDatesPassed, setAllDatesPassed] = useState(false)
  const [isBooked, setIsBooked] = useState(false)

  useEffect(() => {
    apiCalendar
      .getSingle(token)
      .then((res) => {
        if (res.type.toLowerCase() === 'form') navigate(`/interview/${token}`)
        setData(res)
        if (res.isBooked) {
          setIsBooked(res.isBooked)
          const declineStatus = { color: '#000000', name: 'Declined' }
          const isDeclined = res?.status?.find(
            (item) =>
              item.name === declineStatus.name &&
              item.color === declineStatus.color,
          )
          if (isDeclined)
            msgRef.current = t('pages.youDeclined', {
              position: res.position.title,
            })
          else msgRef.current = t('pages.youBookedTime')
          return
        }
        msgRef.current = t('pages.bookDate')
        const localTimeZone = dayjs.tz.guess()

        let allPassed
        const localDates = res?.availableDates?.map((date) => dayjs(date).tz(localTimeZone).format('YYYY-MM-DD HH:mm:ss'))
        if(localDates) allPassed = localDates.every((date) => dayjs(date).isBefore(dayjs()))
        else allPassed = []

        setAllDatesPassed(allPassed)
        setIsLoaded(true)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setInvalidLink(true)
        msgRef.current =
          error?.response?.data?.description || t('pages.interviewExpired')
      })
  }, [token])

  const handleDateClick = (date) => {
    setSelectedDateTime(date)
  }

  const handleDecline = () =>
    apiCalendar.declineInterview(token).then(() => navigate(0))

  const handleSubmit = () => {
    if (selectedDateTime) {
      const formattedDateTime = dayjs(selectedDateTime).format(
        'YYYY-MM-DD HH:mm:ss',
      )
      const utcDateTime = dayjs(selectedDateTime)
        .utcOffset(0)
        .format('YYYY-MM-DDTHH:mm:ss[Z]')

      apiCalendar
        .patch(token, { localTime: formattedDateTime, utc: utcDateTime })
        .then(({ interviewToken }) => {
          if (role) navigate(`/interview/${interviewToken}?role=${role}&room-code=${roomCode}`)
          else navigate(`/interview/${interviewToken}`)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error submitting interview details:', error)
        })
    }
  }

  const companyDetailsData = useMemo(
    () => ({
      location: data?.company?.workingLocations.map((location, index) => (
        <span className="me-1" key={location}>
          {formatCountryName(location)}
          {index < (data?.company?.workingLocations.length ?? 0) - 1 && ','}
        </span>
      )),
      size: data?.company?.companySize,
      website: data?.company?.website,
      industry: data?.company?.industry,
      logo: data?.company.logo,
    }),
    [data],
  )

  const jobDetailsData = useMemo(
    () => ({
      desc: data?.position?.description,
      startDate: data?.position?.startDate,
      endDate: data?.position?.endDate,
    }),
    [data],
  )

  if (!isLoaded && !invalidLink && isBooked) {
    return (
      <NotificationBoxPage
        messageType="warning"
        message="Your Interview has already been booked"
        rescheduleEnabled={false}
      />
    )
  }

  if (!isLoaded && !invalidLink && !isBooked) {
    return <LoadingPage />
  }

  if (
    (allDatesPassed && data.type.toLowerCase() === 'inperson') ||
    invalidLink ||
    (data.type.toLowerCase() === 'inperson' &&
      role !== 'broadcaster' &&
      role !== 'candidate' &&
      role !== 'viewer')
  ) {
    return (
      <NotificationBoxPage
        messageType="error"
        message={t('pages.linkExpired')}
        rescheduleEnabled={false}
      />
    )
  }

  if (role === 'broadcaster' || role === 'viewer') {
    return (
      <NotificationBoxPage
        messageType="warning"
        message="Waiting for Candidate to book an interview"
        rescheduleEnabled={false}
      />
    )
  }

  return (
    <MainContainer centered>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      <StyledContainer fluid="md">
        <StyledRow className="calendar-page">
          <StyledCol md={12} lg={6}>
            {data?.type?.toLowerCase() !== 'inperson' ? (
              <CalendarPicker
                title={msgRef.current}
                token={token}
                endDate={data?.position?.endDate}
              />
            ) : (
              <div style={{ backgroundColor: theme.white, height: '100%' }}>
                <Clock style={{ flexGrow: 1 }}>
                  <Title>{msgRef.current}</Title>
                  <Row className="w-100">
                    <ul>
                      {data.availableDates.map((date) => {
                        const isPassed = dayjs(date).isBefore(dayjs())
                        return (
                          <ButtonContainer key={date}>
                            <PrimaryButton
                              color={
                                isPassed
                                  ? theme.talentinoGray
                                  : theme.talentinoSpecialBlue
                              }
                              backgroundcolor={
                                selectedDateTime === date
                                  ? theme.talentinoDarkerWhiteBlue
                                  : theme.talentinoWhiteBlue
                              }
                              hovercolor={theme.talentinoDarkerWhiteBlue}
                              onClick={() => handleDateClick(date)}
                              disabled={isPassed}
                            >
                              {dayjs(date).format('LLL')}
                            </PrimaryButton>
                          </ButtonContainer>
                        )
                      })}
                    </ul>
                  </Row>
                  <Row style={{ width: '50%' }}>
                    <Col md={6} sm={12} className="p-1">
                      <PrimaryButton
                        width="100%"
                        style={{ whiteSpace: 'nowrap' }}
                        backgroundcolor="transparent"
                        border={`1px solid ${theme.talentinoRed}`}
                        hovercolor={theme.talentinoRed}
                        color={theme.talentinoRed}
                        onClick={handleDecline}
                      >
                        {t('calendar.declineInvitation')}
                      </PrimaryButton>
                    </Col>
                    <Col md={6} sm={12} className="p-1">
                      <PrimaryButton
                        width="100%"
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={handleSubmit}
                        disabled={!selectedDateTime}
                      >
                        {t('general.submit')}
                      </PrimaryButton>
                    </Col>
                  </Row>
                </Clock>
              </div>
            )}
          </StyledCol>
          <StyledColFlex>
            <Row style={{ position: 'relative' }}>
              <CalendarLogoContainer>
                <LogoImage
                  src={data?.company.logo || defaultLogo}
                  alt="logo"
                  width="80px"
                />
              </CalendarLogoContainer>
              <CalendarCard height="100%">
                <CardHeader>{data?.company?.companyName}</CardHeader>
                <Separator />
                <CompanyDetails {...companyDetailsData} />
              </CalendarCard>
            </Row>
            <StyledRowAuto>
              <CalendarCard height="100%">
                <CardHeader>{data?.position?.title}</CardHeader>
                <Separator />
                <JobDetails {...jobDetailsData} />
              </CalendarCard>
            </StyledRowAuto>
          </StyledColFlex>
        </StyledRow>
      </StyledContainer>
    </MainContainer>
  )
}

export default Calendar
