/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from './Input'
import SelectInput from './SelectInput'
import FormDiscardButton from '../buttons/FormDiscardButton'
import FileInput from './FileInput'
import FormSubmitWithLoading from '../buttons/FormSubmitWIthLoading'
import InlineRadioInput from './InlineRadioInput'

export default function AbstractForm({
  inputs,
  defaultValues,
  schema,
  actions,
  width,
  handleFormWatch,
  isDisabledSubmit,
  resetOnSubmit,
  stopLoading,
  children,
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const methods = useForm({
    defaultValues: React.useMemo(() => defaultValues, [defaultValues]),
    resolver: schema ? yupResolver(schema) : null,
  })

  const renderInput = (input) => {
    switch (input.renderType) {
      case 'select':
        return <SelectInput {...input} />
      case 'files':
        return <FileInput {...input} />
      case 'radio':
        return <InlineRadioInput {...input} />
      default:
        return <Input {...input} />
    }
  }

  const onSubmit = (data) => {
    setIsLoading(true)
    actions
      .filter((action) => action.field === 'submit')
      .forEach((action) => {
        if (resetOnSubmit) methods.reset(defaultValues)
        action.onClick(data, setIsLoading)
      })
  }

  React.useEffect(() => {
    const subscription = methods.watch((value) => {
      handleFormWatch(value)
    })
    return () => subscription.unsubscribe()
  }, [methods.watch])

  React.useEffect(() => {
    if (stopLoading) setIsLoading(false)
  }, [stopLoading])

  React.useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  return (
    <Container className="px-0">
      <Row>
        <Col sm={width}>
          <FormProvider {...methods}>
            <Container className="px-0">
              <Row>
                {inputs?.map((input) => (
                  <Col key={input.name} sm={input.width}>
                    {renderInput(input)}
                  </Col>
                ))}
              </Row>
              {children ? (
                <Row>
                  <Col>{children}</Col>
                </Row>
              ) : null}
              <Row className="justify-content-end mt-2">
                {actions?.map((action) => (
                  <Col sm="auto" key={action.text}>
                    {action.field === 'submit' ? (
                      <FormSubmitWithLoading
                        text={action.text}
                        isLoading={isLoading}
                        onSubmit={onSubmit}
                        isDisabled={isDisabledSubmit}
                      />
                    ) : (
                      <FormDiscardButton
                        variant="outline-primary"
                        onClick={action.onClick}
                        text={action.text}
                        isLoading={isLoading}
                      />
                    )}
                  </Col>
                ))}
              </Row>
            </Container>
          </FormProvider>
        </Col>
      </Row>
    </Container>
  )
}

AbstractForm.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      renderType: PropTypes.string,
      type: PropTypes.string,
      width: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.any.isRequired,
          label: PropTypes.any.isRequired,
        }),
      ),
      helperText: PropTypes.string,
    }),
  ),
  defaultValues: PropTypes.object,
  schema: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  width: PropTypes.number,
  children: PropTypes.node,
  handleFormWatch: PropTypes.func,
  isDisabledSubmit: PropTypes.bool,
  resetOnSubmit: PropTypes.bool,
  stopLoading: PropTypes.bool,
}

AbstractForm.defaultProps = {
  width: 12,
  children: null,
  defaultValues: {},
  schema: null,
  inputs: [],
  handleFormWatch: () => {},
  isDisabledSubmit: false,
  actions: [],
  resetOnSubmit: false,
  stopLoading: false,
}
