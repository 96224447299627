import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckOne } from '../assets/svgs/check-one.svg'
import { ReactComponent as Frown } from '../assets/svgs/frown.svg'
import { ReactComponent as ExclamationCircle } from '../assets/svgs/exclamationCircle.svg'
import theme from '../constants/theme'
import { MainContainer, NotificationBox } from './styled'
import PrimaryButton from './buttons/PrimaryButton'

function MessageComponent({ message, color, icon }) {
  return (
    <NotificationBox color={color}>
      {icon}
      <p>{message}</p>
    </NotificationBox>
  )
}

MessageComponent.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}

function NotificationBoxPage({
  messageType,
  message,
  rescheduleInterview,
  rescheduleEnabled,
  isFinished,
  isExpired,
}) {
  const { t } = useTranslation()
  const renderMessage = (type) => {
    const messageObject = {
      message,
      color: theme.talentinoGreen,
      icon: <CheckOne style={{ width: '48px', height: '48px' }} />,
    }

    switch (type) {
      case 'success':
        messageObject.color = theme.talentinoGreen
        messageObject.icon = (
          <CheckOne style={{ width: '48px', height: '48px' }} />
        )
        break
      case 'error':
        messageObject.color = theme.talentinoDarkerRed
        messageObject.icon = <Frown style={{ width: '48px', height: '48px' }} />
        break
      case 'warning':
        messageObject.color = "#FFC107"
        messageObject.icon = <ExclamationCircle style={{ width: '48px', height: '48px', fill: "#FFC107" }} />
        break
      default:
        messageObject.color = theme.talentinoBlue
        messageObject.icon = (
          <CheckOne style={{ width: '48px', height: '48px' }} />
        )
        break
    }
    return MessageComponent(messageObject)
  }

  return (
    <MainContainer backgroundColor={theme.talentinoSkyBlue}>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100 h-100 p-3"
        style={{ flexGrow: 1 }}
      >
        {renderMessage(messageType)}
        {rescheduleEnabled && !isFinished && isExpired && (
          <PrimaryButton
            color={theme.talentinoSpecialBlue}
            backgroundcolor={theme.talentinoWhiteBlue}
            hovercolor={theme.talentinoDarkerWhiteBlue}
            onClick={rescheduleInterview}
          >
            {t('preInterview.rescheduleInterview')}
          </PrimaryButton>
        )}
        <div
          className="fixed-bottom"
          style={{
            marginLeft: '30px',
            marginBottom: '30px',
            display: 'inline-flex',
            padding: '15px',
          }}
        >
          <p
            style={{
              color: theme.talentinoDarkerGray,
              fontSize: '13px',
              fontWeight: '400',
            }}
          >
            © {new Date().getFullYear()}
            <span style={{ fontWeight: '600' }}>
              {' '}
              {t('footer.TalentinoAI')}
            </span>
            . {t('footer.developedBy')}{' '}
            <span style={{ fontWeight: '600' }}>{t('footer.AreteGlobal')}</span>
          </p>
        </div>
      </div>
    </MainContainer>
  )
}

export default NotificationBoxPage

NotificationBoxPage.propTypes = {
  messageType: PropTypes.string,
  message: PropTypes.string,
  rescheduleInterview: PropTypes.func,
  rescheduleEnabled: PropTypes.bool,
  isFinished: PropTypes.bool,
  isExpired: PropTypes.bool,
}

NotificationBoxPage.defaultProps = {
  messageType: 'error',
  message: '',
  rescheduleInterview: () => {},
  rescheduleEnabled: false,
  isFinished: false,
  isExpired: false,
}
