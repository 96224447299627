import ApiCore from './utilities/core'
import apiProvider from './utilities/provider'

const url = 'position-eliminating-questions'
const plural = 'position-eliminating-questions'
const single = 'position-eliminating-questions'

// plural and single may be used for message logic if needed in the ApiCore class.
const apiPositionEliminatingQuestions = new ApiCore({
  getAll: true,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url,
  plural,
  single,
})

apiPositionEliminatingQuestions.getAllByPosition = (id) =>
  apiProvider.getAll(`${url}/${id}`)

export default apiPositionEliminatingQuestions
