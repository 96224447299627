import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types'

export default function VideoPreview({ stream }) {
    const videoRef = useRef(null)
  
    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream
      }
    }, [stream])
  
    if (!stream) {
      return null
    }
  
    return (
      <video
        ref={videoRef}
        height="303px"
        width="100%"
        autoPlay
        controls
        className="rounded"
      >
        <track kind="captions" srcLang="en" label="English" />
      </video>
    )
  }
  
  VideoPreview.propTypes = {
    stream: PropTypes.instanceOf(MediaStream),
  }

  VideoPreview.defaultProps = {
    stream: null,
  }
  
  
  