import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import theme from '../constants/theme'
import FullLogo from '../assets/logos/full-logo.png'
import LanguagesDropdown from '../components/languageDropdwn'
import {
  CareerDot,
  CareerGrid,
  CareerLocation,
  CareerLogo,
  CareerNoVacanciesContainer,
  CareerNoVacanciesMessage,
  CareerParentLine,
  CareerSeparator,
  CareerText,
  CareerTitle,
  CareerTitleContainer,
  CareerWord,
  Footer,
  FooterContent,
  GradientBackground,
} from '../components/styled'
import apiCompanyPositions from '../services/api/companyPosition'
import { ReactComponent as Inbox } from '../assets/svgs/inbox.svg'
import PositionCard from '../components/careers/PositionCard'

function CareerPage() {
  const { t } = useTranslation()
  const { companyId } = useParams()
  const [positions, setPositions] = useState([])
  useEffect(() => {
    apiCompanyPositions
      .getPublicActivePositions(companyId)
      .then((result) => {
        setPositions(result)
      })
      .catch(console.log) // eslint-disable-line no-console
  }, [companyId])

  return (
    <GradientBackground
      className="vw-100 vh-100 overflow-auto d-flex justify-content-center position-relative"
      background={theme.gradientBackground}
    >
      <Container className="mt-1 fw-light">
        <Row className="my-2 align-items-end">
          <Col sm="auto">
            <img height="auto" width="190px" src={FullLogo} alt="Full Logo" />
          </Col>
          <Col />
          <Col sm="1">
            <LanguagesDropdown />
          </Col>
        </Row>
        <div className="mt-2 d-flex flex-column justify-content-center align-items-center gap-3">
          {positions.length === 0 ? (
            <CareerNoVacanciesContainer>
              <Inbox width="55px" height="55px" />
              <CareerNoVacanciesMessage>
                {t('careers.noOpenPositions')}
              </CareerNoVacanciesMessage>
            </CareerNoVacanciesContainer>
          ) : (
            <>
              <CareerTitleContainer>
                <div
                  className="d-flex justify-content-center align-items-center border rounded p-2"
                  style={{
                    width: '100px',
                    height: '100px',
                    borderColor: theme.talentinoSpecialBlue,
                    backgroundColor: theme.talentinoOfWhite,
                  }}
                >
                  <CareerLogo
                    src={positions[0]?.company?.logo}
                    alt="Company Logo"
                  />
                </div>
                <CareerTitle>{positions[0]?.company?.companyName}</CareerTitle>
                <CareerLocation>
                  <CareerText fontSize={14} fontWeight={400}>
                    {positions[0]?.company?.workingLocations[0]}
                  </CareerText>
                  <CareerDot />
                  <CareerText fontSize={14} fontWeight={400}>
                    {positions[0]?.company?.website}
                  </CareerText>
                </CareerLocation>
              </CareerTitleContainer>
              <CareerSeparator>
                <CareerParentLine />
                <CareerWord>{t('careers.activePositions')}</CareerWord>
                <CareerParentLine />
              </CareerSeparator>
              <CareerGrid>
                {positions.map((position) => (
                  <PositionCard key={position.id} position={position} />
                ))}
              </CareerGrid>
            </>
          )}
        </div>
      </Container>

      <Footer className="fixed-bottom">
        {t('footer.TalentinoAI')}{' '}
        <FooterContent>© {new Date().getFullYear()}</FooterContent>{' '}
        {t('footer.allRightsReserved')}
      </Footer>
    </GradientBackground>
  )
}
export default CareerPage
