/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { Typography } from '../styled'

function SelectInput({
  name,
  label,
  placeholder,
  helperText,
  required,
  options,
  disabled,
  ...rest
}) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <div className="mb-2">
          <Form.Label className="text-muted m-0">
            <Typography size="14px">
              {label}
              {required && '*'}
            </Typography>
          </Form.Label>
          <Form.Select
            disabled={disabled}
            isInvalid={!!fieldState.error}
            onChange={field.onChange}
            value={field.value}
            placeholder={placeholder}
            {...rest}
          >
            <option value={null} className="d-none">
              {null}
            </option>
            {options.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Text>
            {fieldState.error ? fieldState.error.message : helperText}
          </Form.Text>
        </div>
      )}
    />
  )
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired,
    }),
  ),
  disabled: PropTypes.bool,
}

SelectInput.defaultProps = {
  disabled: false,
  placeholder: '',
  helperText: '',
  required: false,
  options: [{ value: '', label: '' }],
}
export default SelectInput
