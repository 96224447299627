import styled from '@emotion/styled'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import theme from '../constants/theme'

export const DropdownToggleBtn = styled(DropdownToggle)(
  ({
    backgroundColor = 'transparent !important',
    color = theme.headerItemColor,
    borderRadius = '0px',
    fontWeight = 500,
  }) => ({
    height: '40px',
    boxShadow: 'none !important',
    color,
    fontWeight,
    backgroundColor,
    border: '0',
    borderRadius,
    position: 'relative',
    '&:hover': {
      color,
      backgroundColor,
    },
    '&:focus': {
      backgroundColor,
      color,
    },
  }),
)

export const StyledDropdownMenu = styled(DropdownMenu)(({ width }) => ({
  margin: '0',
  padding: '0',
  border: 'none',
  listStyle: 'none',
  width,
  backgroundColor: theme.white,
  zIndex: '9999',
  borderRadius: '0',
  boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
  transition: 'all 0.2s ease-in-out',
  transform: 'translateY(90px)',
}))

export const NotificationItemText = styled('div')(({ backgroundColor }) => ({
  padding: '0.75rem 1rem',
  height: 'fit-content',
  backgroundColor,
  '&:hover': {
    backgroundColor: '#c4c7d1',
  },
}))
