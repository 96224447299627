import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as Yup from 'yup'
import * as jose from 'jose'
import AbstractForm from '../components/forms/AbstractForm'
import {
  Footer,
  FooterContent,
  QuestionAnswerFormContainer,
  QuestionFormContainer,
  FormTitleContainer,
  GradientBackground,
  FormContainer,
} from '../components/styled'
import apiInterviewVersions from '../services/api/interviewVersion'
import FullLogo from '../assets/logos/full-logo.png'
import QuestionCard from '../components/formInterviewComponents/questionCard'
import theme from '../constants/theme'
import LoadingPage from '../components/loadingPage'
import LanguagesDropdown from '../components/languageDropdwn'

export default function FormInterview() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { interviewToken } = useParams()
  const versionId = jose.decodeJwt(interviewToken).interview_version_id
  const [questions, setQuestions] = useState([])
  const [positionTitle, setPositionTitle] = useState('')
  const [inputs, setInputs] = useState([])
  const [isDisableSubmit, setIsDisableSubmit] = useState(false)
  const [savedData, setSavedData] = useState({})
  const [defaultValues, setDefaultValues] = useState({})

  const schema = Yup.object().shape(
    questions?.reduce((acc, _, index) => {
      acc[`answer_${index}`] = Yup.string().required()
      return acc
    }, {}),
  )

  const handleSubmitAnswers = (data) => {
    const formData = {
      interviewToken,
      data: {
        questions_data: [],
      },
    }

    questions.forEach((question, index) => {
      formData.data.questions_data.push({
        answer: data[`answer_${index}`],
        versionId,
        used: true,
        question_id: questions[index].id,
      })
    })

    apiInterviewVersions.updateFormQuestionByCandidate(formData).then(() => {
      localStorage.removeItem('isReady')
      navigate(0)
    })
  }

  useEffect(() => {
    apiInterviewVersions.startInterview(interviewToken).then((data) => {
      setQuestions(data.questions)
      setPositionTitle(data.position)
      const initialInputs = []
      data.questions?.forEach((question) => {
        const field = {
          name: `answer_${question.index}`,
          label: (
            <QuestionFormContainer>
              <span style={{ color: theme.brandColorOrange }}>
                Q{question.index + 1}.
              </span>{' '}
              {question.question}{' '}
              <span style={{ color: theme.brandColorOrange }}>*</span>
            </QuestionFormContainer>
          ),
          type: 'text',
          style: {
            background: '#fff',
          },
          as: 'textarea',
          width: 12,
          required: true,
        }
        initialInputs.push(field)
      })
      setInputs(initialInputs)
      setDefaultValues({ ...defaultValues, ...savedData })
    })
  }, [])

  const actions = [
    {
      field: 'submit',
      text: t('general.submit'),
      onClick: handleSubmitAnswers,
    },
  ]

  const handleFormWatch = (data) => {
    setSavedData(data)
  }

  const handleFormWatchDisable = (data) => {
    if (Object.keys(data).length) {
      setIsDisableSubmit(false)
      Object.keys(data).forEach((key) => {
        if (!data[key]) setIsDisableSubmit(true)
      })
    }
  }

  if (questions?.length === 0) return <LoadingPage />

  return (
    <GradientBackground
      className="vh-100 overflow-hidden position-relative"
      background={theme.gradientBackground}
    >
      <Row className="h-100 mt-n3 position-relative">
        <Row className="my-2 align-items-end">
          <Col sm="auto">
            <img height="auto" width="130px" src={FullLogo} alt="" />
          </Col>
          <Col />
          <Col sm="1">
            <LanguagesDropdown />
          </Col>
        </Row>
        <Row className="m-3 align-items-center position-relative">
          <FormContainer>
            <FormTitleContainer>
              <QuestionCard positionTitle={positionTitle} />
            </FormTitleContainer>
            <QuestionAnswerFormContainer>
              <AbstractForm
                inputs={inputs}
                defaultValues={defaultValues}
                actions={actions}
                isDisabledSubmit={isDisableSubmit}
                handleFormWatch={(data) => {
                  handleFormWatch(data)
                  handleFormWatchDisable(data)
                }}
                schema={schema}
              />
            </QuestionAnswerFormContainer>
          </FormContainer>
        </Row>
      </Row>
      <Footer className="fixed-bottom">
        {t('footer.TalentinoAI')}{' '}
        <FooterContent>© {new Date().getFullYear()}</FooterContent>{' '}
        {t('footer.allRightsReserved')}
      </Footer>
    </GradientBackground>
  )
}
