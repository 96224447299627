import apiProvider from './utilities/provider'

const url = 'company-positions'

const apiCompanyPositions = {
  getSingleWithToken: (token) =>
    apiProvider.getSingle(`${url}/application`, token),
  getPublicActivePositions: (companyId) =>
    apiProvider.getSingle(`${url}/public`, companyId),
}

export default apiCompanyPositions
