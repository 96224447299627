import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  JobDetailsDetailsContent,
  JobDetailsDetailsHeader,
  JobDetailsDetailsSection,
  JobDetailsDetailsWrapper,
} from '../styled'

function JobDetails({ desc, startDate, endDate }) {
  const { t } = useTranslation()

  const details = [
    { label: t('card.description'), content: desc?.replace(/<[^>]+>/g, '') },
    { label: t('card.startDate'), content: startDate },
    { label: t('card.endDate'), content: endDate },
  ]

  return (
    <JobDetailsDetailsWrapper>
      {details.map(({ label, content }) => (
        <JobDetailsDetailsSection borderTop key={label}>
          <JobDetailsDetailsHeader>{label} :</JobDetailsDetailsHeader>
          <JobDetailsDetailsContent>{content}</JobDetailsDetailsContent>
        </JobDetailsDetailsSection>
      ))}
    </JobDetailsDetailsWrapper>
  )
}

JobDetails.propTypes = {
  desc: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

JobDetails.defaultProps = {
  startDate: null,
  endDate: null,
};

export default JobDetails;
