/* eslint-disable no-console */
import { initializeApp } from 'firebase/app'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from 'firebase/storage'
import { nanoid } from 'nanoid'

const firebaseConfig = {
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

const storageApi = {}

storageApi.interviewUpload = async (
  file,
  interviewVersionId,
  questionId,
  recordingOrder,
) => {
  const fileUrl = `interview-versions/${interviewVersionId}/Q-${questionId}/Q_${questionId}_${recordingOrder}.webm`
  const fileRef = ref(storage, fileUrl)

  const uploadTask = uploadBytesResumable(fileRef, file)

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log(`Upload is ${progress}% done`)
    },
    (error) => {
      console.log('Upload failed', error)
    },
    () => {
      console.log('File uploaded successfully')
    },
  )

  return fileUrl
}

storageApi.uploadTest = async (file, fileUrl) => {
  const fileRef = ref(storage, fileUrl)
  const startTime = Date.now()
  await uploadBytes(fileRef, file)
  const endTime = Date.now()
  const durationInSeconds = (endTime - startTime) / 1000
  const fileSizeInMB = file.size / (1024 * 1024)
  const speedInMbps = (fileSizeInMB / durationInSeconds) * 8
  return speedInMbps
}

storageApi.deleteTest = async (file) => {
  const desertRef = ref(storage, file)
  await deleteObject(desertRef)
}

storageApi.uploadFile = (file, onUpload, onSuccess, onError) => {
  const fileExtension = file.name.split('.').at(-1)
  const fileUrl = `resumes/${nanoid(27)}.${fileExtension}`
  const fileRef = ref(storage, fileUrl)

  const uploadTask = uploadBytesResumable(fileRef, file)

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      onUpload(Math.ceil(progress))
    },
    (error) => {
      // Handle unsuccessful uploads
      let errorMessage = ''
      switch (error.code) {
        case 'storage/unauthorized':
          errorMessage = 'you can only upload pdf with size less than 5 MB'
          break
        case 'storage/canceled':
          errorMessage = 'the upload has been canceled'
          break
        default:
          errorMessage = 'something went wrong please try again later'
      }
      if (onError) onError(errorMessage)
    },
    () => {
      // Handle successful uploads on complete
      if (onSuccess) onSuccess(fileUrl)
    },
  )
  return uploadTask.then()
}

export default storageApi
