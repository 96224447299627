import React from 'react'
import PropTypes from 'prop-types'
import RadioQuestion from './Answers/RadioQuestion'
import MultiChoiceQuestion from './Answers/MultiChoiceAnswer'
import RangeQuestion from './Answers/RangeQuestion'
import NumberQuestion from './Answers/NumberQuestion'

function EliminatingQuestion({ question, index, setEliminatingAnswers }) {
  switch (question.answerType) {
    case 'range':
      return (
        <RangeQuestion
          question={question}
          index={index}
          setEliminatingAnswers={setEliminatingAnswers}
        />
      )
    case 'radio':
      return (
        <RadioQuestion
          question={question}
          index={index}
          setEliminatingAnswers={setEliminatingAnswers}
        />
      )
    case 'number':
      return (
        <NumberQuestion
          question={question}
          index={index}
          setEliminatingAnswers={setEliminatingAnswers}
        />
      )
    default:
      return (
        <MultiChoiceQuestion
          question={question}
          index={index}
          setEliminatingAnswers={setEliminatingAnswers}
        />
      )
  }
}

export default EliminatingQuestion

EliminatingQuestion.defaultProps = {
  setEliminatingAnswers: () => {},
  index: 0,
  question: {},
}

EliminatingQuestion.propTypes = {
  setEliminatingAnswers: PropTypes.func,
  index: PropTypes.number,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answerType: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answer: PropTypes.object.isRequired,
    show: PropTypes.bool,
  }),
}
