/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as jose from 'jose'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  useHMSStore,
  useHMSActions,
  selectIsConnectedToRoom,
} from '@100mslive/react-sdk'
import FullLogo from '../assets/logos/full-logo.png'
import { ReactComponent as TimeCircle } from '../assets/svgs/timeCircle_blue.svg'
import ProgressBar from '../components/progressBar'
import {
  ContentContainer,
  ImageContainer,
  InterviewQuestionTag,
  InterviewQuestionText,
  InterviewQuestionsContainerBox,
  InterviewQuestionsTextContainer,
  InterviewTimerBox,
  InterviewTimerText,
  MainContainer,
  QuestionsProgressCard,
  QuestionsProgressContainer,
  QuestionsProgressText,
  QuestionsProgressTitle,
  QuestionsProgressTitleContainer,
  Separator,
} from '../components/styled'
import apiInterviewVersions from '../services/api/interviewVersion'
import RecordingSectionThirdParty from '../components/interviewVideo/RecordingSectionThirdParty'
import LoadingPage from '../components/loadingPage'
import theme from '../constants/theme'
import PrimaryButton from '../components/buttons/PrimaryButton'

function VideoInterview() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const hmsActions = useHMSActions()
  const candidateRoleRoomCode = localStorage.getItem('candidateRoleRoomCode')
  const candidateRoleRoomName = localStorage.getItem('candidateRoleRoomName')
  const isConnected = useHMSStore(selectIsConnectedToRoom)
  const { interviewToken } = useParams()
  const interviewId = jose.decodeJwt(interviewToken).interview_version_id
  const [question, setQuestion] = useState('')
  const [questionsNumber, setQuestionsNumber] = useState(0)
  const [questionProgress, setQuestionProgress] = useState(1)
  const [idOfCurrentQuestions, setIdOfCurrentQuestions] = useState()
  const [recordingStatus, setRecordingStatus] = useState('notAnswered')
  const [isLoading, setIsLoading] = useState(false)
  const [minutesInterview, setMinutesInterview] = useState(15)
  const [secondsInterview, setSecondsInterview] = useState(0)

  const endRoom = async () => {
    try {
      const lock = false
      const reason = 'Interview has ended'
      await hmsActions.endRoom(lock, reason)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const handleSubmitAnswer = async () => {
    setIsLoading(true)
    apiInterviewVersions
      .sendAnswer({
        interviewToken,
        questionId: idOfCurrentQuestions,
        video: `interview-versions/${interviewId}/Q-${idOfCurrentQuestions}.mp4`,
        audio: `interview-versions/${interviewId}/Q-${idOfCurrentQuestions}.wav`,
      })
      .then(() => {
        if (questionProgress === questionsNumber) {
          endRoom()
          localStorage.clear()
          navigate(0)
        } else
          apiInterviewVersions.getNextQuestion(interviewToken).then((res) => {
            setQuestion(res.question.toString())
            setQuestionProgress(questionProgress + 1)
            setIdOfCurrentQuestions(res.id)
            setRecordingStatus('notAnswered')
          })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    apiInterviewVersions.startInterview(interviewToken).then((data) => {
      if (data && data.remainingQuestion) {
        setQuestion(data.question.toString())
        setQuestionProgress(data.index + 1)
        setIdOfCurrentQuestions(data.id)
        setRecordingStatus('notAnswered')
        const duration = data.duration.split('.')[0].split(':')
        setMinutesInterview(duration[1])
        setSecondsInterview(duration[2])
      } else {
        localStorage.clear()
        navigate(0)
      }
    })
    apiInterviewVersions
      .getNumberOfQuestionsByInterview(interviewToken)
      .then((res) => setQuestionsNumber(res.questions))
  }, [interviewToken, navigate])

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (secondsInterview > 0) {
        setSecondsInterview(secondsInterview - 1)
      }
      if (secondsInterview === 0) {
        if (minutesInterview === 0) {
          clearInterval(countdownInterval)
          localStorage.clear()
          navigate(0)
        } else {
          setMinutesInterview(minutesInterview - 1)
          setSecondsInterview(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(countdownInterval)
    }
  }, [recordingStatus, secondsInterview, minutesInterview, navigate])

  useEffect(() => {
    const joinRoom = async () => {
      const authToken = await hmsActions.getAuthTokenByRoomCode({
        roomCode: candidateRoleRoomCode,
      })
      try {
        await hmsActions.join({ userName: candidateRoleRoomName, authToken })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
    if (!isConnected) {
      joinRoom()
    }
  }, [isConnected])

  if (!question) return <LoadingPage />

  return (
    <MainContainer>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      <ContentContainer
        justifyContent="auto"
        marginTop="50px"
        width="100vw"
        padding="0px"
      >
        <Row className="w-100">
          <Col xs={12} md={8} lg={9}>
            <RecordingSectionThirdParty
              idOfCurrentQuestions={idOfCurrentQuestions}
              recordingStatus={recordingStatus}
              setRecordingStatus={setRecordingStatus}
              isLoading={isLoading}
            />
            <InterviewQuestionsContainerBox>
              <InterviewQuestionTag>
              {t('interview.question')}:{' '}
                <span
                  style={{
                    color: theme.talentinoBlue,
                  }}
                >
                  {questionProgress}
                </span>
              </InterviewQuestionTag>
              <InterviewQuestionsTextContainer>
                <InterviewQuestionText>{question}</InterviewQuestionText>
                {questionProgress === questionsNumber ? (
                  <PrimaryButton
                    backgroundcolor={theme.talentinoGreen}
                    hovercolor={theme.talentinoDarkerGreen}
                    width="90px"
                    disabled={recordingStatus !== 'stopped'}
                    onClick={handleSubmitAnswer}
                  >
                    {t('general.submit')}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    backgroundcolor={
                      recordingStatus === 'recording' ||
                      recordingStatus === 'loading'
                        ? theme.talentinoDisabledBlue
                        : recordingStatus === 'stopped'
                        ? theme.talentinoBlue
                        : theme.talentinoGray
                    }
                    hovercolor={
                      recordingStatus === 'recording' ||
                      recordingStatus === 'loading'
                        ? theme.talentinoDisabledDarkerBlue
                        : recordingStatus === 'stopped'
                        ? theme.talentinoDarkerBlue
                        : theme.talentinoGray
                    }
                    width="90px"
                    disabled={recordingStatus !== 'stopped'}
                    type="button"
                    onClick={handleSubmitAnswer}
                  >
                    {t('pages.nextQuestion')}
                  </PrimaryButton>
                )}
              </InterviewQuestionsTextContainer>
            </InterviewQuestionsContainerBox>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <QuestionsProgressCard>
              <QuestionsProgressTitleContainer>
                <QuestionsProgressTitle>
                {t('interview.interviewTimeWithTalentino')}
                </QuestionsProgressTitle>
              </QuestionsProgressTitleContainer>
              <Separator />
              <InterviewTimerBox>
                <InterviewTimerText>
                  <TimeCircle style={{ width: '20px', height: '20px' }} />
                  {minutesInterview} :{' '}
                  {secondsInterview < 10
                    ? `0${secondsInterview}`
                    : secondsInterview}
                </InterviewTimerText>
              </InterviewTimerBox>
            </QuestionsProgressCard>
            <QuestionsProgressCard>
              <QuestionsProgressTitleContainer>
                <QuestionsProgressTitle>
                {t('interview.questionProgress')}
                </QuestionsProgressTitle>
              </QuestionsProgressTitleContainer>
              <Separator />
              <QuestionsProgressContainer>
                <QuestionsProgressText>
                {t('interview.questions')}{' '}
                  <span
                    style={{
                      color: theme.talentinoBlue,
                    }}
                  >
                    ({questionProgress}/{questionsNumber})
                  </span>
                </QuestionsProgressText>
                <ProgressBar
                  bgcolor={theme.talentinoBlue}
                  progress={questionProgress}
                  height={10}
                  leftText={questionsNumber}
                />
              </QuestionsProgressContainer>
            </QuestionsProgressCard>
          </Col>
        </Row>
      </ContentContainer>
    </MainContainer>
  )
}

export default VideoInterview
