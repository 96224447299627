import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import { Container, Row, Spinner } from 'react-bootstrap'
import FullLogo from '../assets/logos/full-logo.png'
import {
  Footer,
  FooterContent,
  GradientBackground,
  NotificationMessagePageBox,
  Typography,
} from './styled'
import theme from '../constants/theme'
import BaseButton from './buttons/BaseButton'

function MessageComponent({ headerMessage, message, color }) {
  return (
    <NotificationMessagePageBox color={color} className="p-3">
      <Row className="fs-5 m-3">
        <Typography>{headerMessage}</Typography>
      </Row>
      <Row className="fs-normal m-3">
        <Typography color="black">{message}</Typography>
      </Row>
    </NotificationMessagePageBox>
  )
}

MessageComponent.propTypes = {
  headerMessage: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}
export default function NotificationMessagePage({
  messageType,
  headerMessage,
  message,
  rescheduleInterview,
  rescheduleEnabled,
  isFinished,
  isExpired,
}) {
  const renderMessage = (type) => {
    const messageObject = {
      headerMessage,
      message,
      color: theme.brandColorOrange,
    }

    switch (type) {
      case 'success':
        messageObject.color = theme.success
        break
      case 'warning':
        messageObject.color = theme.brandColorOrange
        break
      case 'error':
        messageObject.color = theme.brandColorOrange
        break
      case 'loading':
        messageObject.color = theme.gray300
        break
      default:
        messageObject.color = theme.brandColorOrange
        break
    }
    return MessageComponent(messageObject)
  }

  const { t } = useTranslation()

  return (
    <GradientBackground
      className="vw-100 vh-100 overflow-hidden d-flex justify-content-center position-relative"
      background={theme.gradientBackground}
    >
      <Container>
        <Row className="h-100 mt-n3">
          <Row className="my-2">
            <Col sm="auto">
              <img height="auto" width="130px" src={FullLogo} alt="" />
            </Col>
            <Col />
          </Row>

          <Row className="m-3 align-items-center h-75 ">
            <Col className="text-center d-flex justify-content-center flex-wrap">
              {messageType === 'loading' ? (
                <Spinner
                  animation="border"
                  variant="secondary"
                  style={{ height: '3rem', width: '3rem' }}
                />
              ) : (
                renderMessage(messageType)
              )}
              {rescheduleEnabled && !isFinished && isExpired && (
                <BaseButton
                  className="my-3 w-50"
                  backgroundcolor={theme.brandColorOrange}
                  hoverbackgroundcolor={theme.brandColorOrange}
                  onClick={rescheduleInterview}
                >
                  Reschedule Interview
                </BaseButton>
              )}
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer className="fixed-bottom">
        {t('footer.TalentinoAI')}{' '}
        <FooterContent>© {new Date().getFullYear()}</FooterContent>{' '}
        {t('footer.allRightsReserved')}
      </Footer>
    </GradientBackground>
  )
}

NotificationMessagePage.propTypes = {
  messageType: PropTypes.string,
  headerMessage: PropTypes.string,
  message: PropTypes.string,
  rescheduleInterview: PropTypes.func,
  rescheduleEnabled: PropTypes.bool,
  isFinished: PropTypes.bool,
  isExpired: PropTypes.bool,
}

NotificationMessagePage.defaultProps = {
  messageType: 'error',
  headerMessage: '',
  message: '',
  rescheduleInterview: () => {},
  rescheduleEnabled: false,
  isFinished: false,
  isExpired: false,
}
