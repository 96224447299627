import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import Form from 'react-bootstrap/Form'

export default function FileInput({
  name,
  label,
  placeholder,
  helperText,
  required,
  ...rest
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <Form.Group className="mb-2">
      <Form.Label className="text-muted m-0">
        {label}
        {required && '*'}
      </Form.Label>
      <Form.Control
        type="file"
        {...register(name)}
        placeholder={placeholder}
        {...rest}
      />
      <Form.Text>{errors[name] ? errors[name].message : helperText}</Form.Text>
    </Form.Group>
  )
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
}

FileInput.defaultProps = {
  placeholder: '',
  helperText: '',
  required: false,
}
