export const source = [
  { value: 'linkedIn', en: 'LinkedIn', ar: 'لينكد إن', gr: 'linkedIn' },
  { value: 'indeed', en: 'Indeed', ar: 'إنديد', gr: 'indeed' },
  { value: 'wuzzuf', en: 'Wuzzuf', ar: 'وظف', gr: 'wuzzuf' },
  { value: 'bayt', en: 'Bayt', ar: 'بيت', gr: 'bayt' },
  { value: 'facebook', en: 'Facebook', ar: 'فيسبوك', gr: 'facebook' },
  {
    value: 'careerSite',
    en: 'Career site',
    ar: 'موقع التوظيف',
    gr: 'Karriere Seite',
  },
  {
    value: 'talentAcquisitionDep',
    en: 'Talent Acquisition Dep',
    ar: 'الفريق الداخلي',
    gr: 'Abteilung für Talentakquise',
  },
  {
    value: 'recruitmentAgency',
    en: 'Recruitment Agency',
    ar: 'وكالة توظيف',
    gr: 'Personal Agentur',
  },
  {
    value: 'Freelance Recruiter',
    en: 'Freelance Recruiter',
    ar: 'المجند المستقل',
    gr: 'Freiberuflicher Personalvermittler',
  },
  { value: 'referral', en: 'Referral', ar: 'الإحالة', gr: 'Verweisung' },
  { value: 'other', en: 'Other', ar: 'آخر', gr: 'Andere' },
]

export const noticePeriodOptions = [
  { en: 'immediately', ar: 'حالا', gr: 'sofort', value: 0 },
  { en: 'one week', ar: 'أسبوع', gr: 'Eine Woche', value: 7 },
  { en: 'two weeks', ar: 'اسبوعين', gr: 'zwei Wochen', value: 14 },
  { en: 'one month', ar: 'شهر', gr: 'Ein Monat', value: 30 },
  { en: 'two months', ar: 'شهرين', gr: 'Zewi Monate', value: 60 },
]
