import { useLayoutEffect, useState } from 'react'

const debounce = (fn, delay) => {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(fn, delay, [...args])
  }
}

export default function useWindowSize() {
  const [size, setSize] = useState([0])

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth])
    }

    const debouncedUpdateSize = debounce(updateSize, 200)

    window.addEventListener('resize', debouncedUpdateSize)
    updateSize()
    return () => window.removeEventListener('resize', debouncedUpdateSize)
  }, [])

  return size
}
