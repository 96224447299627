import React from 'react';
import PropTypes from 'prop-types'
import VideoPreview from './videoPreview';

export default function RecordView({
  isRecording,
  mediaBlobUrl,
  previewStream,
}) {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      {isRecording ? (
        <VideoPreview stream={previewStream} />
      ) : (
          <video
            id="recorded"
            src={mediaBlobUrl}
            controls
            autoPlay
            height="303px"
            className="rounded"
          >
            <track kind="captions" srcLang="en" label="English" />
          </video>
      )}
    </div>
  )
}

RecordView.propTypes = {
  isRecording: PropTypes.bool.isRequired,
  mediaBlobUrl: PropTypes.string,
  previewStream: PropTypes.instanceOf(MediaStream),
}


RecordView.defaultProps = {
  mediaBlobUrl: undefined,
  previewStream: null,
}
