import apiProvider from './provider'

export default class ApiCore {
  constructor(options) {
    if (options.getAll) {
      this.getAll = (config) => apiProvider.getAll(options.url, config)
    }

    if (options.getSingle) {
      this.getSingle = (id, config) =>
        apiProvider.getSingle(options.url, id, config)
    }

    if (options.post) {
      this.post = (model, config) =>
        apiProvider.post(options.url, model, config)
    }

    if (options.put) {
      this.put = (id, model, config) =>
        apiProvider.put(options.url, id, model, config)
    }

    if (options.patch) {
      this.patch = (id, model, config) =>
        apiProvider.patch(options.url, id, model, config)
    }

    if (options.delete) {
      this.delete = (id, config) => apiProvider.remove(options.url, id, config)
    }
  }
}
