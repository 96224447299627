import ApiCore from './utilities/core'
import apiProvider from './utilities/provider'

const url = 'interview-versions'

const apiInterviewVersions = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  delete: false,
  url,
})

apiInterviewVersions.getQuestionsByInterview = (id) =>
  apiProvider.getAll(`${url}/${id}/questions`)

apiInterviewVersions.getNumberOfQuestionsByInterview = (interviewToken) =>
  apiProvider.getSingle(url, `${interviewToken}/no-of-questions`)

apiInterviewVersions.sendEmailInvitation = (token, data) =>
  apiProvider.patch(url, `${token}/send-invitation`, data)

apiInterviewVersions.startInterview = (interviewToken) =>
  apiProvider.getSingle(url, `${interviewToken}/start`)

apiInterviewVersions.getNextQuestion = (interviewToken) =>
  apiProvider.getSingle(url, `${interviewToken}/unused-question`)

apiInterviewVersions.sendAnswer = ({
  interviewToken,
  questionId,
  video,
  audio,
}) =>
  apiProvider.post(
    `${url}/${interviewToken}/questions/${questionId}/submit-answer`,
    {
      video,
      audio,
    },
  )

apiInterviewVersions.processVideos = ({ interviewToken, data }) =>
  apiProvider.post(`${url}/${interviewToken}/questions/process-videos`,
    data
  )

apiInterviewVersions.logUploadSpeed = ({ interviewToken, data }) =>
  apiProvider.post(`${url}/${interviewToken}/log_upload_speed`,
    data
  )

apiInterviewVersions.updateQuestion = (interview, id, data) =>
  apiProvider.patch(`${url}/${interview}/questions`, id, data)

apiInterviewVersions.updateQuestionByCandidate = ({
  interviewToken,
  questionId,
  data,
}) =>
  apiProvider.patch(
    url,
    `${interviewToken}/questions/${questionId}/interview`,
    data,
  )

  apiInterviewVersions.updateFormQuestionByCandidate = ({
    interviewToken,
    data
  }) =>
    apiProvider.patch(
      url,
      `${interviewToken}/questions/submit-form`,
      data,
    )

apiInterviewVersions.deleteQuestion = (interview, id) =>
  apiProvider.remove(url, `${interview}/questions/${id}`)

apiInterviewVersions.addOneQuestionToVersion = (id, question, index) =>
  apiProvider.post(`${url}/${id}/questions`, { question, index })

apiInterviewVersions.regenerateQuestionsAI = (id, data) =>
  apiProvider.post(`${url}/${id}/regenerate-questions`, data)

apiInterviewVersions.getVersionsCountByCompany = () =>
  apiProvider.getAll(`${url}/count`)

apiInterviewVersions.getManagementToken = () =>
  apiProvider.getAll(`${url}/generate_management_token`)

apiInterviewVersions.logRecordingStarting = ({ interviewToken, data }) =>
  apiProvider.post(`${url}/${interviewToken}/log_recording_starting`,
    data
  )

export default apiInterviewVersions
