import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMachine } from '@xstate/react'
import { Col, Container, Row } from 'react-bootstrap'
import theme from '../../constants/theme'
import {
  Footer,
  FooterContent,
  GradientBackground,
  Typography,
} from '../styled'
import BaseButton from '../buttons/BaseButton'
import FullLogo from '../../assets/logos/full-logo.png'
import interviewMachine from '../interviewMachine'
import apiInterviewVersions from '../../services/api/interviewVersion'
import LanguagesDropdown from '../languageDropdwn'

export default function PreFormInterview() {
  const { t } = useTranslation()
  const { interviewToken } = useParams()
  const [, send] = useMachine(interviewMachine, {
    services: {
      fetchInterview: () =>
        apiInterviewVersions.startInterview(interviewToken).then((res) => res),
    },
  })
  const navigate = useNavigate()
  const handleSubmit = () => {
    localStorage.setItem('isReady', 'true')
    send('startInterview')
    navigate(0)
  }

  return (
    <GradientBackground
      className="vh-100 overflow-hidden d-flex justify-content-center position-relative"
      background={theme.gradientBackground}
    >
      <Container>
        <Row className="h-100 mt-n3">
          <Row className="my-2 align-items-end">
            <Col sm="auto">
              <img height="auto" width="130px" src={FullLogo} alt="" />
            </Col>
            <Col />
            <Col sm="1">
              <LanguagesDropdown />
            </Col>
          </Row>
          <Row className="m-3 align-items-center h-75">
            <Col className="text-center d-flex flex-column justify-content-center">
              <Typography
                color={theme.darkBlue}
                size="25px"
                style={{ fontWeight: 'bold' }}
              >
                {t('preInterview.instructions')}
              </Typography>
              <Typography color={theme.darkBlue} size="20px">
                <ul
                  className="list-unstyled mt-4"
                  style={{ listStyleType: 'none' }}
                >
                  <li>
                    <span style={{ color: theme.success, fontWeight: 'bold' }}>
                      ✔
                    </span>{' '}
                    {t('preInterview.ensureStableInternet')}
                  </li>
                  <li>
                    <span style={{ color: theme.success, fontWeight: 'bold' }}>
                      ✔
                    </span>{' '}
                    {t('preInterview.bePrepared')}
                  </li>
                  <li>
                    <span style={{ color: theme.danger, fontWeight: 'bold' }}>
                      ⚠
                    </span>{' '}
                    {t('preInterview.importantMsg')}
                  </li>
                </ul>
              </Typography>
              <div className="text-center">
                <BaseButton
                  backgroundcolor={theme.brandColorOrange}
                  hoverbackgroundcolor={theme.gray300}
                  onClick={handleSubmit}
                >
                  {t('preInterview.startInterview')}
                </BaseButton>
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer className="fixed-bottom">
        {t('footer.TalentinoAI')}{' '}
        <FooterContent>© {new Date().getFullYear()}</FooterContent>{' '}
        {t('footer.allRightsReserved')}
      </Footer>
    </GradientBackground>
  )
}
