import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button'

const BaseButton = styled(Button)(
  ({
    backgroundcolor,
    hoverbackgroundcolor,
    color = '#FFFFFF',
    fontSize = '1rem',
  }) => ({
    fontFamily: 'PoppinsMedium',
    backgroundColor: backgroundcolor,
    textTransform: 'none',
    color,
    fontSize,
    borderColor: backgroundcolor,
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: hoverbackgroundcolor,
      borderColor: hoverbackgroundcolor,
      color,
    },
    '&:disabled': {
      backgroundColor: hoverbackgroundcolor,
      borderColor: hoverbackgroundcolor,
      color,
    },
    '&:focus': {
      backgroundColor: backgroundcolor,
      borderColor: backgroundcolor,
      color,
    },
    '&:active': {
      backgroundColor: `${backgroundcolor} !important`,
      borderColor: `${backgroundcolor} !important`,
      color,
    },
  }),
)

export default BaseButton
