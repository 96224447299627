import styled from '@emotion/styled'
import Form from 'react-bootstrap/Form'
import theme from '../../constants/theme'

const BaseRadio = styled(Form.Check)(() => ({
  input: {
    backgroundColor: 'transparent',
    borderColor: theme.footerSpanColor,
    borderWidth: '2px',
    '&:checked': {
      backgroundColor: theme.brandColorOrange,
      borderColor: theme.brandColorOrange,
    },
  },
}))

export default BaseRadio
