import axios from 'axios'
import { camelCase } from 'lodash/string'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 55000,
})

instance.interceptors.request.use(
  // Do something before request is sent
  (config) => config,
  // Do something with request error
  (error) => Promise.reject(error),
)

/**
 * function that returns the object recursively with camel case keys
 * @param {Object} data
 * @returns {Object} object with camel case keys
 */
function toCamelCase(data) {
  if (Array.isArray(data)) {
    return data.map((value) => {
      if (value instanceof Object) return toCamelCase(value)
      return value
    })
  }
  if (data instanceof Object) {
    const convertedDate = {}
    Object.entries(data).forEach(([key, value]) => {
      convertedDate[camelCase(key)] = toCamelCase(value)
    })
    return convertedDate
  }
  // base case
  return data
}

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    if (response.results) {
      return toCamelCase(response.results)
    }
    if (response.data) {
      return toCamelCase(response.data)
    }
    return toCamelCase(response)
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  (error) => Promise.reject(error),
)

/** @param {string} resource */
/** @param {object} [config] */
const getAll = (resource, config) => {
  if (config) {
    return instance.get(resource, { ...config })
  }
  return instance.get(resource)
}

/** @param {string} resource */
/** @param {string} id */
/** @param {object} [config] */
const getSingle = (resource, id, config) => {
  if (config) {
    return instance.get(`${resource}/${id}`, { ...config })
  }
  return instance.get(`${resource}/${id}`)
}

/** @param {string} resource */
/** @param {object} model */
/** @param {object} [config] */
const post = (resource, model, config) => {
  if (config) {
    return instance.post(resource, model, { ...config })
  }
  return instance.post(resource, model)
}

/** @param {string} resource */
/** @param {string} id */
/** @param {object} model */
/** @param {object} [config] */
const put = (resource, id, model, config) => {
  if (config) {
    return instance.put(`${resource}/${id}`, model, { ...config })
  }
  return instance.put(`${resource}/${id}`, model)
}

/** @param {string} resource */
/** @param {string} id */
/** @param {object} model */
/** @param {object} [config] */
const patch = (resource, id, model, config) => {
  if (config) {
    return instance.patch(`${resource}/${id}`, model, { ...config })
  }
  return instance.patch(`${resource}/${id}`, model)
}

/** @param {string} resource */
/** @param {string} id */
/** @param {object} [config] */
const remove = (resource, id, config) => {
  if (config) {
    return instance.delete(`${resource}/${id}`, { ...config })
  }
  return instance.delete(`${resource}/${id}`)
}

const apiProvider = {
  instance,
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
}

export default apiProvider
