import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button'
import theme from '../../constants/theme'

const PrimaryButton = styled(Button)(
    ({ width, backgroundcolor, color, hovercolor, border }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 16px',
        height: '36px',
        width: width || '150px',
        borderRadius: '2px',
        background: backgroundcolor || theme.talentinoSpecialBlue,
        border: border || 'none',
        color: color || theme.white,
        fontSize: '12px',
        fontWeight: '500',
        '&:hover': {
          background: hovercolor || theme.talentinoSpecialDarkBlue,
        },
        '&:active': {
          background: `${hovercolor || theme.talentinoSpecialDarkBlue} !important`,
        },
        '&:disabled': {
          color: color || theme.white,
          background: `${backgroundcolor || theme.talentinoSpecialBlue} !important`,
        },
      }),
)

export default PrimaryButton
