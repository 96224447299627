import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMachine } from '@xstate/react'
import FullLogo from '../../assets/logos/full-logo.png'
import instructionImage from '../../assets/images/carousel-2.png'
import {
  CarouselContainer,
  CarouselText,
  CarouselTitle,
  ContentContainer,
  CountDownBoxWrapper,
  CountdownCard,
  CountdownCardBody,
  CountdownCardHeader,
  ImageContainer,
  InstructionsContainer,
  InstructionsText,
  MainContainer,
  PreInterviewCountDownTitle,
  StepsContainer,
  StepsText,
} from '../styled'
import interviewMachine from '../interviewMachine'
import apiInterviewVersions from '../../services/api/interviewVersion'
import PrimaryButton from '../buttons/PrimaryButton'
import theme from '../../constants/theme'

function CountdownItem({ label, value }) {
  return (
    <CountdownCard>
      <CountdownCardHeader>{label}</CountdownCardHeader>
      <CountdownCardBody>{value}</CountdownCardBody>
    </CountdownCard>
  )
}

CountdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

function Timer({ interviewTime, setShowCounter }) {
  const { t } = useTranslation()
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds <= 0) {
        if (minutes <= 0) {
          if (hours <= 0) {
            if (days <= 0) {
              clearInterval(countdownInterval)
              setShowCounter(false)
            } else {
              setDays(days - 1)
              setHours(23)
              setMinutes(59)
              setSeconds(59)
            }
          } else {
            setHours(hours - 1)
            setMinutes(59)
            setSeconds(59)
          }
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(countdownInterval)
    }
  })

  useEffect(() => {
    const time = new Date(`${interviewTime?.toString()}`)
    const now = new Date()
    const remainingTime = time - now
    if (remainingTime < 0) {
      setShowCounter(false)
    }
    setDays(Math.floor(remainingTime / (1000 * 60 * 60 * 24)))
    setHours(
      Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    )
    setMinutes(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)))
    setSeconds(Math.floor((remainingTime % (1000 * 60)) / 1000))
  }, [interviewTime])
  return (
    <CountDownBoxWrapper>
      <PreInterviewCountDownTitle>
        {t('preInterview.yourInterviewStarts')}{' '}
      </PreInterviewCountDownTitle>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ gap: '2px' }}
      >
        <CountdownItem label={t('preInterview.days')} value={days} />
        <CountdownItem label={t('preInterview.hours')} value={hours} />
        <CountdownItem label={t('preInterview.minutes')} value={minutes} />
        <CountdownItem label={t('preInterview.seconds')} value={seconds} />
      </div>
    </CountDownBoxWrapper>
  )
}

Timer.propTypes = {
  interviewTime: PropTypes.string,
  setShowCounter: PropTypes.func.isRequired,
}

Timer.defaultProps = {
  interviewTime: '',
}

function TextInstructions({ setReadInstructions }) {
  const { t } = useTranslation()
  const instructionTitle = t('preInterview.connectionInstructions')
  const instructionContent = t('preInterview.sureInternetStable')

  const handleStartInterview = () => {
    setReadInstructions(true)
  }
  return (
    <>
      <InstructionsContainer>
        <InstructionsText>{t('preInterview.instructions')}</InstructionsText>
      </InstructionsContainer>
      <StepsContainer>
        <StepsText>
          {t('preInterview.beforeInterview')}{' '}
          <span className='fw-bold'>
            {t('preInterview.essentialSteps')}
          </span>{' '}
          {t('preInterview.toPrepare')}
        </StepsText>
      </StepsContainer>
      <ContentContainer>
        <CarouselContainer>
          <img height="303px" src={instructionImage} alt="" />
          <CarouselTitle>{instructionTitle}</CarouselTitle>
          <CarouselText>{instructionContent}</CarouselText>
          <PrimaryButton onClick={handleStartInterview}>
            {t('preInterview.startInterview')}
          </PrimaryButton>
        </CarouselContainer>
      </ContentContainer>
    </>
  )
}

TextInstructions.propTypes = {
  setReadInstructions: PropTypes.func.isRequired,
}

export default function PreTextInterview({
  rescheduleInterview,
  rescheduleEnabled,
  isFinished,
  interviewTime,
  setShowCounter,
  showCounter,
}) {
  const { t } = useTranslation()
  const [readInstructions, setReadInstructions] = useState(false)
  const { interviewToken } = useParams()
  const [, send] = useMachine(interviewMachine, {
    services: {
      fetchInterview: () =>
        apiInterviewVersions.startInterview(interviewToken).then((res) => res),
    },
  })
  const navigate = useNavigate()
  useEffect(() => {
    if (!showCounter) {
      send('startInterview')
      navigate(0)
    }
  }, [showCounter])
  return (
    <MainContainer>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      {!readInstructions ? (
        <TextInstructions setReadInstructions={setReadInstructions} />
      ) : (
        <ContentContainer>
          <Timer
            interviewTime={interviewTime}
            setShowCounter={setShowCounter}
          />
          {rescheduleEnabled && !isFinished && (
          <div className="d-flex justify-content-end align-items-center p-3">
            <PrimaryButton
            color={theme.talentinoSpecialBlue}
            backgroundcolor={theme.talentinoWhiteBlue}
            hovercolor={theme.talentinoDarkerWhiteBlue}
            onClick={rescheduleInterview}
            >
              {t('preInterview.rescheduleInterview')}
            </PrimaryButton>
          </div>
          )}
        </ContentContainer>
      )}
    </MainContainer>
  )
}

PreTextInterview.propTypes = {
  rescheduleInterview: PropTypes.func,
  interviewTime: PropTypes.string.isRequired,
  setShowCounter: PropTypes.func.isRequired,
  showCounter: PropTypes.bool.isRequired,
  rescheduleEnabled: PropTypes.bool,
  isFinished: PropTypes.bool,
}

PreTextInterview.defaultProps = {
  rescheduleInterview: () => {},
  rescheduleEnabled: false,
  isFinished: false,
}
