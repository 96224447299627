import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import theme from '../../constants/theme'
import BaseOutlineButton from './BaseOutlineButton'

function FormDiscardButton({ handleClick, text, isLoading, ...rest }) {
  return (
    <BaseOutlineButton
      hoverbackgroundcolor={theme.brandColorOrange}
      disablecolor={theme.brandColorLightOrange}
      color={theme.brandColorOrange}
      onClick={handleClick}
      disabled={isLoading}
      {...rest}
    >
      {isLoading && (
        <Spinner
          animation="border"
          variant="light"
          size="sm"
          className="me-2"
        />
      )}
      {text}
    </BaseOutlineButton>
  )
}

FormDiscardButton.propTypes = {
  handleClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
}

FormDiscardButton.defaultProps = {
  handleClick: () => {},
  isLoading: false,
}

export default FormDiscardButton
