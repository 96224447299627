import React, { forwardRef } from 'react'
import theme from '../../constants/theme'

const ProgressBar = forwardRef(
  (props, { progressRef, errorRef, componentRef }) => (
    <>
      <div ref={componentRef}>
        {componentRef && <code />}
        <div className="progress mt-2 mb-1">
          <div
            ref={progressRef}
            className="progress-bar"
            role="progressbar"
            style={{
              width: '0',
              backgroundColor: theme.brandColorOrange,
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            0%
          </div>
        </div>
      </div>
      <code ref={errorRef} />
    </>
  ),
)

export default ProgressBar
